.home-section {
  background-color: white !important;
}

.img-edit {
  width: 53%;
}

.igrZou {
  display: block !important;
  grid-template-columns: repeat(3, 1fr) !important;
  grid-template-rows: repeat(1, 1fr) !important;
  grid-gap: 30px !important;
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 270px;
}

.line {
  margin-top: 20px;
  width: 270px;
  background: #382d8b;
  height: 1px;
  float: left;
}

.point {
  width: 0;
  height: 0;
  margin-top: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #382d8b;
  float: right;
}

.lead-home {
  font-weight: 600;
  font-size: 42px;
  line-height: 101.5%;
  text-align: center;
  color: #3a3a3c;
}

.lead-home-test {
  font-weight: 600;
  font-size: 42px;
  line-height: 101.5%;
  text-align: center;
  color: #3a3a3c;
}

.right-padding {
  padding-right: 5rem !important;
}
.edit-border {
  background: rgba(56, 45, 139, 0.19);
  border-radius: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: 1080px;
  margin: 0 auto;
}
.edit-border-video {
  background: rgba(56, 45, 139, 0.19);
  border-radius: 40px;
  height: 100%;
  padding: 70px 0px;
  width: fit-content;
  max-width: 1080px;
  margin: 0 auto;
}
.icon-right-p {
  padding-right: 1rem;
}
.why-edyter {
  padding: 120px 0px;
}
.ant-rate {
  box-sizing: border-box !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 40px !important;
  font-variant: tabular-nums !important;
  line-height: 1.5715 !important;
  font-feature-settings: "tnum", "tnum" !important;
  display: inline-block;
  white-space: nowrap !important;
  margin: 0;
  padding: 0;
  color: #f8a401 !important;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.bold-num {
  font-weight: 700;
  color: #3a3a3c;
}

.testimonail-box {
  background: #ffffff;
  box-shadow: 0px 33px 82px -4px rgba(0, 0, 0, 0.06);
  border-radius: 40px;
  margin-top: 2rem;
}

.comment-test {
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #3a3a3c;
}

.testimonal-head {
  font-weight: 600;
  font-size: 19px;
  line-height: 74px;
  /* identical to box height */
  white-space: nowrap;
  text-align: center;
  letter-spacing: 0.02em;
  color: #50469c;
}

.bhRnqO::before {
  content: "";
  height: 10px;
  width: 10px;
  background: transparent;
  border-top: 2px solid #fff !important;
  border-right: 2px solid #fff !important;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-75%, -50%) rotate(45deg);
  -ms-transform: translate(-75%, -50%) rotate(45deg);
  transform: translate(-75%, -50%) rotate(45deg);
}

.kVtMtA::before {
  content: "";
  height: 10px;
  /* color: red !important; */
  width: 10px;
  background: transparent;
  border-top: 2px solid #fff !important;
  border-right: 2px solid #fff !important;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-25%, -50%) rotate(-135deg);
  -ms-transform: translate(-25%, -50%) rotate(-135deg);
  transform: translate(-25%, -50%) rotate(-135deg);
}

.system-flex-arrow {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  height: 100%;
  display: none !important;
  margin-top: 5rem;
  margin-bottom: -3rem;
}

.bhRnqO {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 55px !important;
  width: 55px !important;
  background: #382d8b !important;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  opacity: 8 !important;
  font-size: 10px;
  -webkit-transition: 0 !important;
  transition: opacity 0 !important;
  left: initial;
  right: -23px !important;
}

.kVtMtA {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 55px !important;
  width: 55px !important;
  background: #382d8b !important;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  color: white !important;
  cursor: pointer;
  opacity: 8 !important;
  font-size: 10px;
  -webkit-transition: 0 !important;
  transition: opacity 0.25s;
  left: -23px !important;
  right: initial;
}
.testimonial-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 150px;
}

.container-custom-text {
  padding: 0px 300px;
}

.testimonial-p {
  margin-right: 5rem;
}

.testimonal-dyter {
  padding-top: 100px;
  margin: 0 auto;
  width: fit-content;
  max-width: 1080px;
}
.edyter-phases {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phase-sub {
  font-weight: 400;
  font-size: 25px;
  line-height: 60px;
  margin-bottom: 1.5rem;
  width: 300px;
  color: #3a3a3c;
  line-height: 35px;
}

.edyter-phases-head {
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height */

  text-align: right;

  color: #382d8b;
}
.show-mobile {
  display: none !important;
}

.flex-edit {
  display: flex;
  align-items: center;
}
.statement {
  font-weight: 700;
  font-size: 35px;
  display: flex;
  line-height: 50px;
  justify-content: center;
  color: #3a3a3c;
  align-items: center;
  height: 100%;
}

.statement-flex {
  display: flex;
  align-items: center;
}

.edit-caption {
  font-weight: 600;
  font-size: 45px;
  line-height: 134.5%;
  color: #3a3a3c;
}
.content-part {
  padding: 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.content-part-statement {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.img-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-bordered {
  padding: 50px 150px;
}

.circle-inner {
  font-weight: 700;
  font-size: 26px;
  line-height: 101.5%;
  /* identical to box height, or 67px */

  text-align: center;

  color: #382d8b;
}

.system-content-flex {
  display: flex;
  align-items: center;
}
.sub-row-text-dark {
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
  font-size: 25px !important;
  color: #3a3a3c;
}
.sub-row-text {
  font-weight: 500;
  margin-top: 1rem;
  text-align: center;
  font-size: 25px;
  color: #382d8b;
}
.arrow-size {
  font-size: 100px;
  color: #f8a401;
  font-weight: 300;
}
.circle-arrow {
  border-radius: 50%;
  border: 1px solid #382d8b;
  box-shadow: 0px 5.77231px 156.028px 30.4444px rgba(0, 0, 0, 0.06);
  width: 75px;
  height: 75px;
}

.system-flex {
  display: flex;
  align-items: center;
}

.video-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10rem;
  width: 95%;
  height: 68vh;
}

.img-P {
  width: 50%;
}

.edit-caption-sub {
  font-weight: 300;
  font-size: 35px;
  margin-top: 2rem;
  line-height: 113.5%;
  color: #3a3a3c;
}

.show-web {
  display: block !important;
}

.rate-space {
  margin-right: 2rem;
}


@media screen and (max-width: 1280px) {
  .hiw-area {
    margin-top: 18rem !important;
    margin-bottom: 8rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 630px) {
  .hiw-area {
    margin-top: 4rem !important;
    margin-bottom: 8rem;

  }
}

@media screen and (min-width: 280px) and (max-width: 400px) {
  .hiw-area {
    margin-top: 5rem !important;
    margin-bottom: 8rem;

  }
}

@media screen and (min-width: 540px) and (max-width: 690px) {
  .hiw-area {
    margin-top: 10rem !important;
    margin-bottom: 8rem;

  }
}

@media screen and (min-width: 912px) {
  .hiw-area {
    margin-top: 2rem !important;
    margin-bottom: 8rem;

  }
}
@media screen and (max-width: 960px) {
  .container-mobile-h {
    padding: 50px;
  }

  .straight-line-arr {
    border-left: 1px solid #382d8b;
    height: 100px;
  }

  .circle-arrow {
    border-radius: 50%;
    border: 1px solid #382d8b;
    box-shadow: 0px 5.77231px 156.028px 30.4444px rgba(0, 0, 0, 0.06);
    width: 45px;
    height: 45px;
  }

  .why-edyter {
    padding: 75px 0px !important;
  }

  .ant-rate {
    box-sizing: border-box !important;
    color: rgba(0, 0, 0, 0.85);
    font-size: 40px !important;
    font-variant: tabular-nums !important;
    line-height: 1.5715 !important;
    font-feature-settings: "tnum", "tnum" !important;
    display: inline-block;
    white-space: nowrap !important;
    margin: 0;
    padding: 0;
    color: #f8a401 !important;
    font-size: 20px !important;
    line-height: unset;
    list-style: none;
    outline: none;
  }

  .content-padding-edit {
    padding-bottom: 50px !important;
  }

  .phase-sub {
    font-weight: 400;
    font-size: 16px;
    line-height: 60px;
    margin-bottom: 1.5rem;
    width: 300px;
    color: #3a3a3c;
    line-height: 35px;
  }

  .rate-space {
    margin-right: 1rem;
    margin-top: -1rem;
  }
  .edit-caption {
    font-weight: 600;
    font-size: 25px;
    line-height: 134.5%;
    color: #3a3a3c;
  }
  .edit-caption-sub {
    font-weight: 300;
    font-size: 18px;
    line-height: 5px;
    color: #3a3a3c;
  }
  .testimonial-p {
    margin-right: 0;
  }

  .testimonial-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
  }
  .show-mobile {
    display: block !important;
  }

  .system-flex-arrow {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    height: 100%;
    margin-top: 2rem;
  }

  .edit-border-video {
    background: rgba(56, 45, 139, 0.19);
    border-radius: 0px;
    height: 100%;
    padding: 0px 0px;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
  }
  .sub-row-text-dark {
    font-weight: 700;
    text-align: center;
    margin-top: 1rem;
    font-size: 18px !important;
    color: #3a3a3c;
  }
  .sub-row-text {
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: -0.1rem;
    text-align: center;
    font-size: 16px;
    color: #382d8b;
  }

  .testimonal-dyter {
    padding: 100px 40px;
  }
  .img-P {
    width: 100%;
  }
  .img-bordered {
    padding: 20px 60px;
  }
  .statement {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
  }

  .top-padding {
    padding-top: 70px !important;
    padding-bottom: 50px !important;
  }

  .content-part {
    padding-top: 50px;
    padding-bottom: 70px;
    padding-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .comment-test {
    font-style: italic;
    font-weight: 400;
    width: 80% !important;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    width: 60%;
    color: #3a3a3c;
  }

  .edyter-whys {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .edyter-phases-head {
    font-weight: 700;
    font-size: 24px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 24px;
    text-align: center;

    color: #382d8b;
  }

  .content-part-statement {
    padding: 50px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .home-section {
    background-color: white !important;
    padding: 0px;
  }
  .show-web {
    display: none !important;
  }
  .carousel-mobile {
    margin-top: -5rem;
    margin-bottom: 4rem;
  }
  .lead-home {
    font-weight: 700;
    font-size: 25px;
    text-align: left;
    line-height: 35px;
    width: 100%;
    color: #3a3a3c;
  }
  .testimonal-head {
    font-weight: 600;
    font-size: 20px;
    line-height: 74px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #50469c;
  }
  .lead-home-test {
    font-weight: 700;
    font-size: 30px;
    text-align: left;
    line-height: 35px;
    width: 100%;
    color: #3a3a3c;
  }
  .edit-border {
    background: rgba(56, 45, 139, 0.19);
    border-radius: 0px;
    margin-top: -2rem;
    padding: 0 !important;
    height: 100%;
  }
  .testimonail-box {
    background: #ffffff !important;
    box-shadow: 0;
  }

  .arrow {
    width: 270px;
  }

  .line {
    margin-top: 20px;
    width: 270px;
    background: #382d8b;
    height: 1px;
    float: right;
  }

  .point {
    width: 0;
    height: 0;
    margin-top: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #382d8b;
    float: right;
  }
}
