/*----------

16. event style

----------*/

@use "../abstract" as *;

// event-shape
.event-shape {
	position: absolute;
	right: 13%;
	top: 325px;
	@media #{$xxl} {
		right: 6%;
	}
	@media #{$xl} {
		right: 0%;
	}
	@media #{$lg} {
		right: 4%;
	}
	@media #{$md,$sm,$xs} {
		display: none;
	}
}
.event-area {
	background-color: $theme-bg;
}
.event-date {
	height: 60px;
	width: 90px;
	background: $theme-color-2;
	display: flex;
	text-align: center;
	justify-content: center;
	line-height: 60px;
	font-size: 36px;
	font-weight: 700;
	color: $heading-color;
}
.event-month {
	background: $white;
	display: flex;
	height: 40px;
	width: 90px;
	justify-content: center;
	line-height: 40px;
	font-size: 15px;
	color: $heading-color;
}
.current-event-box {
	@media #{$xs} {
		margin-bottom: 30px;
	}
}
.current-event-date {
	gap: 10px;
	@media #{$lg} {
		margin-right: -50px;
	}
	@media #{$xs} {
		flex-direction: column;
	}
}
.event-tour {
	background: $white;
	border: 1px solid #f6f8fb;
	display: flex;
	justify-content: space-between;
	width: 560px;
	padding: 18px 30px 16px 30px;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
	@media #{$xs} {
		padding: 18px 10px 16px 10px;
		width: 100%;
	}
}
.event-box-text {
	h3 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 12px;
		line-height: 1;
	}
	span {
		font-size: 14px;
		font-weight: 600;
		margin-right: 20px;
		i {
			margin-right: 5px;
		}
	}
}
.event-arrow {
	color: #7f8a9f;

	height: 45px;
	width: 45px;
	background-color: $theme-bg;
	text-align: center;
	line-height: 45px;
	&:hover {
		color: $white;
		background: $theme-color;
	}
}

// feedback

.feedback-header {
	display: flex;
	margin-bottom: 40px;
	align-items: center;
}
.feedback-icon {
	i {
		position: absolute;
		top: 30px;
		right: 30px;
		font-size: 72px;
		color: #ced5e5;
		z-index: -1;
	}
}
.feedback-body {
	h3 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 20px;
		color: $theme-color;
	}
}
.feedback-content {
	@media #{$md,$sm,$xs} {
		margin-left: 0px;
	}
}
.feedback-items {
	background: $white;
	padding: 30px 30px 30px 30px;
	z-index: 2;
}

.feedback-title {
	margin-left: 20px;
	h4 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 3px;
	}
}







//   gallery-style
.gallery-singel-img {
	img {
		max-width: 100%;
	}
}

// event-inner style
.event_date_inner {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
}
.event_date {
	width: 120px;
	height: 120px;
	background: #f8f8f9;
	text-align: center;
	display: table;
	border-radius: 5px;
	padding: 0 10px;
	@media #{$xl,$lg} {
		width: 100px;
		height: 100px;
	}
}
.aduence-thumb {
	margin-right: 32px;
	img {
		box-shadow: 0px 0px 10px rgba(17, 32, 80, 0.14);
		border-radius: 50%;
		margin-right: -16px;
		border: 4px solid $white;
	}
}
.event_date_inner {
	h4 {
		font-size: 24px;
		font-weight: 900;
		color: $theme-color;
		font-family: "Nunito Sans", sans-serif;
	}
	span {
		color: #171717;
	}
}
.event_info h3 {
	font-size: 20px;
}

.event-time {
	i {
		line-height: 1;
		margin-right: 10px;
	}
}
.event-location {
	i {
		margin-right: 10px;
	}
}
.event-detalis {
	margin-bottom: 10px;
	flex-wrap: wrap;
}
.event-aduence {
	flex-wrap: wrap;
}
.event_info {
	flex-grow: 1;
	@media #{$lg} {
		max-width: 375px;
	}
	@media #{$md} {
		max-width: 350px;
	}
}
.get-ticket-btn {
	margin-right: 15px;
}
.single-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 30px;
	border: 1px solid $border;
	padding: 15px 15px;
	background-color: $white;
	border-radius: 3px;
	@include transition(0.3s);
	@media #{$xl,$lg,$sm,$xs} {
		gap: 20px;
	}
	&:hover {
		box-shadow: 0px 30px 40px rgba(28, 51, 84, 0.07);
	}
}
.single-item .event_info {
	@media #{$sm,$xs} {
		order: 2;
	}
}
// event-meta
.event-meta-thumb {
	display: flex;
	align-items: center;
}
.event-wrapper {
	padding: 30px;
	background: #f8f8f9;
	height: 110px;
	width: 100%;
	border-radius: 4px;
}
.event-select .nice-select {
	&::after {
		height: 8px;
		width: 8px;
		right: 20px;
		color: $heading-color;
	}
	height: 60px;
	width: 100%;
	line-height: 60px;
	position: relative;
	margin-top: -5px;
	border: 0;
	padding: 0 20px;
	span {
		font-size: 16px;
		color: $heading-color;
		font-weight: 600;
	}
}
.nice-select.open .list {
	width: 100%;
	color: $heading-color;
	font-size: 16px;
}
// event-inner
.find-event-info {
	h4 {
		margin-bottom: 35px;
	}
}
.find-event-input {
	i {
		color: $theme-color;
	}
}
.find-event-wrapper {
	position: relative;
}
.find-event-input {
	i {
		position: absolute;
		top: 54%;
		right: 20px;
		transform: translateY(-50%);
	}
}
.find-event-input input {
	width: 100%;
	height: 55px;
	padding: 0 20px;
	outline: none;
	border: 0;
	background: $white;
	border-radius: 4px;
}
.event-sidebar {
	background-color: #f8f8f9;
	padding: 30px 30px;
	border-radius: 5px;
}
// event-details
.event-contact-info {
	h2 {
		margin-bottom: 30px;
		@media #{$md} {
			font-size: 32px;
		}
		@media #{$sm,$xs} {
			font-size: 24px;
		}
	}
}
.introduction-info {
	h4 {
		font-size: 18px;
		margin-bottom: 15px;
	}
}
.introduction-info {
	p {
		margin-bottom: 25px;
	}
}
.even-point-tittle {
	h4 {
		margin-bottom: 15px;
		font-size: 18px;
	}
}
.event-speaker-info {
	h4 {
		font-size: 20px;
		margin-bottom: 35px;
	}
}
.event-speaker-wrapper {
	box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
	border: 1px solid $border;
	background-color: $white;
	padding: 28px 29px 28px 29px;
	border-radius: 5px;
	position: relative;
}
.event-speaker-content {
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #ffffff;
	padding: 13px 20px 19px 20px;
	border-radius: 3px;
	min-width: 240px;
	span {
		font-size: 16px;
		font-weight: 700;
		color: $heading-color;
		display: block;
		margin-bottom: 5px;
	}
	p {
		margin-bottom: 0px;
		line-height: 1;
	}
}
.event-price-info {
	display: flex;
	justify-content: space-between;
}
.event-price {
	display: flex;
}
.event-information-wrapper {
	padding: 30px 30px 30px 30px;
	box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
	background-color: $white;
	border-radius: 5px;
	border: 1px solid $border;
	@media #{$xs} {
		padding: 30px 20px 30px 20px;
	}
}
.event-information-list {
	padding: 30px 0 30px 0px;
	ul {
		li {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid $border;
			padding: 10px 0px 10px 0px;
			flex-wrap: wrap;
			&:last-child {
				border: none;
				padding-bottom: 5px;
			}
		}
		i {
			line-height: 0;

			margin-right: 15px;
		}
	}
}
.information-list {
	display: flex;
	align-items: center;
	&:first-child {
		span {
			font-weight: 600;
		}
	}
}
.old-price {
	margin-left: 10px;
}
.event-price {
	span {
		font-size: 28px;
		font-weight: 900;
		color: $heading-color;
	}
}
.event-ticket-cost {
	span {
		font-weight: 600;
	}
}
.event-sponsor-wrapper {
	border: 1px solid $border;
	padding: 30px 30px;
	border-radius: 5px;
	box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
	background-color: $white;
}
.sopnsor-tittle {
	h4 {
		margin-bottom: 35px;
	}
}
.sponsor-thumb {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 20px;
}
// google-map
.event-map {
	@media #{$md,$sm,$xs} {
		margin-bottom: 30px;
	}
	iframe {
		height: 330px;
		width: 100%;
	}
}

//event select style
.event-select select {
	width: 100%;
	height: 55px;
	padding: 0 20px;
	outline: none;
	border: 0;
	background: $white;
	border-radius: 4px;
}
.event-select {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        border-bottom: 2px solid $heading-color;
        border-right: 2px solid $heading-color;
        transform: rotate(45deg);
        width: 8px;
        height: 8px;
        right: 22px;
        top: 22px;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        padding-left: 15px;
    }
}
