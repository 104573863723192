/*----------

13. skill style

----------*/

@use "../abstract" as *;

.skill-background-img {
	background-position: center;
	background-size: cover;
	border-radius: 5px;
}
.skill-wrapper {
	padding: 65px 190px 65px 190px;
	@media #{$xl} {
		padding: 65px 150px 65px 150px;
	}
	@media #{$lg} {
		padding: 65px 60px 65px 60px;
	}
	@media #{$md} {
		padding: 65px 90px 65px 90px;
	}
	@media #{$sm,$xs} {
		padding: 65px 30px 65px 30px;
	}
}

.skill-content {
	span {
		color: $white;
		font-size: 18px;
		margin-bottom: 15px;
		display: block;
	}
	h3 {
		color: $white;
		font-size: 28px;
		font-weight: 700;
		margin-bottom: 35px;
		br {
			@media #{$xs} {
				display: none;
			}
		}
	}
	.edu-four-btn:hover {
		color: $heading-color;
		background: $white;
		border-color: $white;
	}
}
.skill-thumb {
	margin-left: 40px;
	@media #{$md,$sm,$xs} {
		margin-left: 0;
		margin-top: 80px;
	}
	img {
		max-width: 100%;
	}
}
.course-price-start {
	background: $theme-color-2;
	height: 100px;
	width: 100px;
	text-align: center;
	color: $white;
	border-radius: 50%;
	top: -40px;
	left: 10px;
	position: absolute;
	padding-top: 22px;
	font-size: 14px;
}
.course-price-start .course-price {
	display: block;
	font-size: 36px;
	font-weight: 900;
}
