/*----------

11. teacher style

----------*/

@use "../abstract" as *;

// shape-detals
.teacher-area {
	overflow-x: clip;
}
.teacher-img {
	margin-bottom: 45px;
	@media #{$md,$sm,$xs} {
		margin-bottom: 95px;
	}
}
.teacher-main-img {
	padding-left: 60px;
	max-width: 100%;
}
.teacher-shape {
	position: absolute;
	top: 10px;
	left: 0;
	z-index: -10;
}
.teacher-shape-02 {
	position: absolute;
	left: 0;
	bottom: -45px;
}
.teacher-shape-03 {
	position: absolute;
	top: -113px;
	left: -126px;
	z-index: -1;
}
.teacher-shape-04 {
	position: absolute;
	top: 100px;
	left: -140px;
	z-index: -1;
}
.zoom-thumb {
	margin-left: 50px;
	@media #{$md,$sm,$xs} {
		margin-left: 0;
	}
}
.zoom-shape-02 {
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0px 20px 80px rgba(28, 51, 84, 0.2);
	@media #{$lg} {
		right: 11%;
	}
}
.zoom-shape-01 {
	position: absolute;
	top: -60px;
	left: -7%;
	z-index: -1;
	-webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
	-moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
	-o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
	animation: hero-thumb-sm-animation 4s linear infinite alternate;
	@media #{$xs} {
		display: none;
	}
}
.zoom-class-wrapper {
	@media #{$md,$sm,$xs} {
		margin-bottom: 60px;
		margin-top: -40px;
	}
}
.zoom-thumb-main-img {
	max-width: 100%;
}
