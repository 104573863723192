.border-card {
  background: #ffffff;
  box-shadow: 0px 3.18864px 71.7444px rgba(163, 171, 185, 0.24);
  border-radius: 15.9432px;
  height: 117px;
  border-radius: 20px;
  padding: 20px 40px;
}

.preview-content {
  font-size: 15px;
  line-height: 35px;
  white-space: pre-line;
}

.download-pdf {
  padding: 0px 40px;
}

.label-area {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #575a5f;
}

.preview-container {
  left: 166px;
  top: 150px;
  padding: 50px 90px;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 19px;
}

.is-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.flex-contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rate-padon {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.additional-comment {
  border: 1px solid rgba(131, 134, 146, 0.56) !important;
  border-radius: 11px;
  white-space: pre-wrap !important;
  padding: 18px;
  width: 100%;
  font-size: 17px !important;
}

.rate-quest {
  font-weight: 500;
  font-size: 17.6568px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #283342;
}

.col-review-box {
  left: 166px;
  top: 150px;
  width: 40%;
}

.rating-content-box {
  margin: 0 auto;
  width: 100%;
}

.container-table {
  padding: -10px 40px;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

.col-preview-box {
  left: 166px;
  top: 150px;
  width: 65%;
  border: 1px solid #d6d6c2;
  border-radius: 10px;
}

.place-order-btn {
  border: 0px solid #382d8b !important;
  padding: 2px 22px;
  color: white;
  line-height: 33px;
  border-radius: 3.2564 !important;
  text-align: center;
  height: 40px;
  background: #382d8b;
  border-radius: 0.985891px;
}

.mute-text-p {
  color: #27292c;
  padding-left: 0.4rem;
}

.tot-orders {
  font-weight: 600;
  line-height: 24px;
  padding-top: 0.8rem;
  font-size: 14px;
  padding-left: 0.4rem;
  /* identical to box height, or 150% */
  color: #27292c;
}

.lead-bold-num {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  /* or 150% */

  display: flex;
  align-items: center;

  color: #27292c;
}

.bord-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.card-ed {
  background: #ffffff !important;
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24) !important;
  border-radius: 20px !important;
  height: 100%;
  border: 0px solid #fff !important;
}

.empty-space {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bold-lead {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #27292c;
}

.display-arrow {
  display: none !important;

}

.top-pad {
  padding: 0px 20px;
}

.card-ed-top {
  padding: 20px 0px;
}

.gutter-right {
  margin-right: 2rem;
}

.main-eds {
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 60px)) 1fr;
  grid-gap: 40px;
  padding: 60px 0;
}

.text--medium {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #ecf0f1;
}

.cards-eds {
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 60px));
  grid-gap: 40px;
}

.card-eds {
  grid-column-end: span 4;
  border-radius: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #2196f3;
  padding: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.flex-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 920px) {
  .rating-content-box {
    margin: 0 auto;
    width: 100%;
  }

  .flex-dashboard {
    display: block;
  }

  .border-card {
    background: #ffffff;
    box-shadow: 0px 3.18864px 71.7444px rgba(163, 171, 185, 0.24);
    border-radius: 15.9432px;
    height: 117px;
    border-radius: 20px;
    margin-bottom: 2rem;
    padding: 20px 40px;
  }

  .col-review-box {
    left: 166px;
    top: 150px;
    width: 100%;
  }

  .mute-text-p {
    color: #27292c !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    padding-left: 0.4rem;
  }

  .place-order-btn {
    border: 0px solid #382d8b !important;
    padding: 3px 22px;
    color: white;
    margin-top: 1.2rem;
    line-height: 33px;
    border-radius: 3.2564 !important;
    text-align: center;
    height: 44px;
    background: #382d8b;
    border-radius: 0.985891px;
  }

  .preview-container {
    left: 166px;
    top: 150px;
    padding: 50px 0px;
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 19px;
  }

  .additional-comment {
    border: 1px solid rgba(131, 134, 146, 0.56) !important;
    border-radius: 11px;
    padding: 18px;
    width: 100% !important;
    white-space: pre-wrap !important;
    font-size: 17px !important;
  }

  .rate-padon {
    margin-top: 2rem;
    margin-bottom: 8rem;
  }

  .flex-contact {
    display: block;
  }
}