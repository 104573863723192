.col-lead {
  font-style: bold;
  font-weight: 800;
  font-size: 27.866px;
  margin-bottom: 3rem;
  line-height: 13px !important;
  /* identical to box height */
  color: #333333;
}

.input-show-eye {
  margin-left: -30px;
  cursor: pointer;
}

.forgot-password-bord {
  background: #ffffff;
  box-shadow: 0px 3px 5px 3px rgba(163, 171, 185, 0.36);
  border-radius: 20px;
  padding: 5px 30px;
}

.signup-form-wrapper-new {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.form-inputs {
  width: 400px !important;
}

.example::-webkit-scrollbar {
  display: none;
}

.forgot-password-b {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}

@media screen and (max-width: 960px) {
  .forgot-password-b {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
  }
  .col-lead {
    font-style: bold;
    font-weight: 800;
    font-size: 27.866px;
    line-height: 13px !important;
    /* identical to box height */

    color: #333333;
  }
}

.is-disabled {
  pointer-events: none;
}
