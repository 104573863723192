.inputError {
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #ff5b5b;
  display: flex;
  align-content: center;
  align-items: center;
}

*input{
  border: none !important;
  outline: 1px solid #B7B7B7 !important;
  outline-color: none !important;
  
  }
  
  *input:focus{
    outline: border !important;
    border: 1px solid #B7B7B7 !important;
    outline-color: none !important;
  }


.label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  /* identical to box height */
  color: #575a5f;
}

.input-profile {
  height: 47px;
  border: 1px solid #e9ebf0 !important;
  border-radius: 14.5234px;
}

::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16.1905px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #b7b7b7;
}

.validate-error {
  color: red;
  font-size: 13px;
}
