.carousel-card-body {
  height: 60vh;
}

@media screen and (max-width: 960px) {
  .carousel-card-body {
    height: max-content;
  }
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
}

.carousel-control-next-icon:after {
  content: ">";
  font-size: 55px;
  color: red;
  margin-right: -20rem;
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 55px;
  color: red;
  margin-left: -20rem;
}
