/*----------

25. faq style

----------*/

@use "../abstract" as *;

// faq style
.faq-tittle-info {
	h3 {
		font-size: 28px;
		@media #{$xs} {
			font-size: 26px;
		}
	}
}
.faq-wrapper.mb-30 {
	min-height: calc(100% - 30px);
	border: 1px solid $border;
	@include transition(0.3s);
	border-radius: 4px;
	&:hover {
		background: $theme-color;
		color: $white;
		border-color: $theme-color;
		box-shadow: 0px 40px 40px rgba(24, 44, 74, 0.1);
	}
}
.faq-question-item {
	@include transition(0.3s);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 24px 20px;
	gap: 5px;
	&:hover {
		color: $white;
	}
	a {
		font-size: 18px;
		&::before {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
}
.faq-nav-tab {
	display: flex;
	justify-content: center;
	margin-top: -104px;
	position: relative;
}
.faq-nav-tab .nav-tabs .nav-item:first-child .nav-link {
	border-radius: 5px 0 0 5px;
}
.faq-nav-tab .nav-tabs .nav-item:last-child .nav-link {
	border-radius: 0 5px 5px 0;
}
.faq-nav-tab .nav-tabs {
	border-bottom: 0;
	padding: 25px 25px;
	background: $white;
	border-radius: 7px;
	max-width: 410px;
}
.faq-nav-tab .nav-tabs .nav-link {
	margin-bottom: 0;
	border: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	padding: 0 50px;
	height: 55px;
	background-color: #f2f4f6;
	font-size: 18px;
	font-weight: 600;
	color: $heading-color;
	@media #{$xs} {
		padding: 0 20px;
		font-size: 16px;
	}
}
.faq-nav-tab .nav-tabs .nav-link.active {
	background-color: $theme-color;
	color: $white;
}
.faqicon-arrow {
	position: relative;
	a {
		border: 1px solid #edeef2;
		border-radius: 5px;
		color: #8c929f;
		width: 35px;
		height: 35px;
		content: "";
		text-align: center;
		line-height: 35px;
		display: inline-block;
		font-size: 15px;
	}
}
.faq-wrapper:hover .faqicon-arrow a {
	background-color: #1352cf;
	box-shadow: 0px 40px 40px rgba(24, 44, 74, 0.1);
}
.faq-wrapper:hover .faq-question-item a {
	border-color: transparent;
	color: $white;
}

.faq-topic-title {
	h3 {
		font-size: 28px;
		@media #{$xs} {
			font-size: 26px;
		}
	}
}
.topic-items {
	background: transparent;
	border: 1px solid $border;
	padding: 50px 25px 35px 25px;
	border-radius: 5px;
	@include transition(0.3s);
	&:hover {
		box-shadow: 0px 40px 40px rgba(24, 44, 74, 0.07);
	}
}
.topic-wrapper:hover .topic-svg {
	transform: translateY(-10px);
}
.topic-svg {
	transition: 0.3s;
	svg {
		margin-bottom: 33px;
	}
}
.topic-content {
	h4 {
		font-size: 20px;
		margin-bottom: 20px;
	}
	p {
		margin-bottom: 0;
	}
}



.faq-detalis-info {
	h3 {
		font-size: 28px;
		margin-bottom: 25px;
		@media #{$xs} {
			font-size: 26px;
		}
	}
}
.faq-suppoert ul li::marker {
	background: #7a7878;
	font-size: 25px;
}
.faq-detalis-info {
	p {
		margin-bottom: 35px;
	}
}
.faq-suppoert {
	p {
		margin-bottom: 23px;
	}
}
.faq-suppoert {
	h5 {
		font-size: 18px;
		margin-bottom: 15px;
	}
}
.payment-method-info {
	h5 {
		font-size: 18px;
		margin-bottom: 15px;
	}
}

.faq-suppoert {
	ul {
		padding-left: 60px;
		margin-bottom: 35px;
		@media #{$xs} {
			padding-left: 20px;
		}
		li {
			position: relative;
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0px;
			}
			&:before {
				position: absolute;
				top: 10px;
				left: -20px;
				content: "";
				background: #7a7878;
				height: 8px;
				width: 8px;
				border-radius: 8px;
			}
			a {
				color: $theme-color;
				font-size: 16px;
				font-weight: 600;
				text-decoration: underline;
				font-weight: 600;
			}
		}
	}
}
.faq-sidebar-topc {
	border: 1px solid $border;
	padding: 30px 30px 40px 30px;
	background: #fcfdff;
	border-radius: 8px;
	h5 {
		margin-bottom: 25px;
		font-size: 20px;
	}
}
.sidebar-topic-list .contact-btn {
	width: 100%;
}
.sidebar-topic-list {
	ul {
		margin-bottom: 45px;
		li {
			font-size: 16px;
			color: #575757;
			margin-bottom: 8px;
			a {
				&:hover {
					color: $theme-color;
				}
			}
		}
	}
}
.payment-method-info {
	a {
		color: $theme-color;
		font-weight: 600;
	}
}
.contact-btn {
	background: $theme-color;
	border-radius: 4px;
	color: $white;
	font-weight: 600;
	display: inline-block;
	text-align: center;
	height: 54px;
	line-height: 54px;
	&:hover {
		color: $heading-color;
		background: $theme-color-2;
	}
}

.faq-content-wrapper {
	margin-right: 20px;
	@media #{$lg,$md,$sm,$xs} {
		margin-right: 0;
	}
}
