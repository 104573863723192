@use "component/common";
@use "component/spacing";
@use "component/header";
@use "component/hero";
@use "component/section-title";
@use "component/category";
@use "component/counter";
@use "component/student-choose";
@use "component/testimonial";
@use "component/education";
@use "component/partner";
@use "component/teacher";
@use "component/blog";
@use "component/skill";
@use "component/eduman-card";
@use "component/campus";
@use "component/event";
@use "component/features";
@use "component/course";
@use "component/instructor";
@use "component/about";
@use "component/banner";
@use "component/shop";
@use "component/membership";
@use "component/zoom-class";
@use "component/faq";
@use "component/contact";
@use "component/footer";

.sing-buttom{
    cursor: pointer;
}

.cont-btn{
    cursor: pointer;
}
