/*----------

26. contact style

----------*/

@use "../abstract" as *;

// contact-style

.contact-from-input input {
	height: 60px;
	width: 100%;
	background: #fff;
	border: none;
	padding: 15px 20px;
	border-radius: 10px;
	outline: 0;
	border: 1px solid #2467EC;
}

.contact-from-input textarea {
	height: 170px;
	width: 100%;
	background: #fff;
	border: none;
	padding: 15px 20px;
	border: 1px solid #2467EC;
	border-radius: 4px;
	resize: none;
	outline: 0;
}

.support-item {
	&:last-child {
		margin-bottom: 0;
	}

	display: flex;
	align-items: flex-start;
	gap: 20px;
	margin-bottom: 30px;

	@media #{$xs} {
		gap: 12px;
	}
}

.support-contact {
	padding: 25px 30px 25px 30px;
	background: #f8f8f9;
	border-radius: 5px;
	border: 1px solid #f8f8f9;

	@media #{$xs} {
		padding: 25px 20px 25px 20px;
	}
}

.support-tittle {
	h4 {
		margin-bottom: 30px;
	}
}

.support-info-phone {
	span {
		font-size: 18px;
		font-weight: 700;
		color: $heading-color;
		margin-bottom: 10px;
		display: block;
	}

	p {
		margin-bottom: 3px;

		a {
			color: $heading-color;
			font-weight: 700;
		}
	}
}

.support-info-email {
	span {
		font-size: 18px;
		font-weight: 700;
		color: $heading-color;
		margin-bottom: 10px;
		display: block;
	}
}

.support-info-email {
	a {
		margin-bottom: 2px;
		display: block;
	}
}

.support-info-location {
	span {
		font-size: 18px;
		font-weight: 700;
		color: $heading-color;
		margin-bottom: 10px;
		display: block;
	}
}

// nice-select
.contact-select .nice-select {
	height: 60px;
	width: 100%;
	background: #f8f8f9;
	border: none;
	padding: 15px 20px;
	line-height: 30px;

	span {
		font-size: 14px;
		color: $body-text;
	}

	&:after {
		width: 7px;
		height: 7px;
		right: 20px;
	}
}

.contact-select .nice-select .list {
	width: 100%;
	background-color: #f8f8f9;
}

.contact-map iframe {
	height: 380px;
	width: 100%;
	border-radius: 4px;
}