.button {
  cursor: pointer;
  font-weight: 500;
  line-height: 23px;
  border: transparent;
  height: 44px;
  background: #382d8b !important;
  outline: none !important;
  outline-color: #382d8b !important;
  color: white;
  border-radius: 2px;
}

.button:hover {
  cursor: pointer;
  font-weight: 500;
  line-height: 23px;
  border: transparent;
  height: 44px;
  background: #f8a401;
  outline: none !important;
  outline-color: #f8a401 !important;
  color: white;
  border-radius: 2px;
}

.disabled-button {
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 0.005em;
  font-weight: 600;
  line-height: 21px;
  border: 1px solid #d3d3d3;
  font-weight: 500;
  outline-color: #d3d3d3 !important;
  line-height: 23px;
  background: #d3d3d3;
  border-radius: 10px;
  height: 60px;
}

.small {
  padding: 10px 20px;
  font-weight: 400;
  height: 40px;
}

.info-btn {
  background: #0163de;
  color: #fff;
}
.orange {
  background: #f8a401;
  color: #fff;
  white-space: nowrap;
  width: 100%;
}

.purple {
  background: #382d8b;
  color: #fff;
  white-space: nowrap;
}

.purple:hover {
  background: #f8a401 !important;
  color: #fff;
  white-space: nowrap;

}
.plain-orange {
  color: #f8a401;
  border: 1px solid #f8a401;
  white-space: nowrap;

}
.plain-green {
  color: #382d8b;
  border: 1px solid rgba(0, 168, 89, 0.1);
  background-color: #fff;
}
.cancel {
  color: #f44336;
  background-color: #fff;
  border: 1px solid #f44336;
}
.button-red-bg {
  background-color: #cf1010 !important;
  color: #fff !important;
}

.btn-disable-plain {
  color: #cfcfcf;
  border: 1px solid #cfcfcf;
  background-color: #fff;
}

@media screen and (max-width: 960px) {
  .info-btn {
    background: #0163de;
    color: #fff;
    white-space: nowrap;
    padding: 0px 50px;
  }
  .orange {
    background: #f8a401;
    color: #fff;
    white-space: nowrap;
    padding: 0px 50px;
    width: 100%;
  }
  
  .purple {
    background: #382d8b;
    white-space: nowrap;
    color: #fff;
  padding: 0px 50px;
  }
  
  .purple:hover {
    background: #f8a401 !important;
    color: #fff;
    padding: 0px 20px;
  }
}
