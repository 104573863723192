.empty-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.empty-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 50px;
  /* or 168% */

  text-align: center;
  color: #b9bec7;
}

@media screen and (max-width: 960px) {
  .empty-text{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #b9bec7;
  }
}
