.info-btnn {
  background: #0163de;
  color: #fff;
  border-radius: 99px;
  text-align: center;
  padding: 2px 10px;
  width: 170px;
  white-space: nowrap;
  cursor: pointer;
}

.redi-btnn {
  background: #a37554;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  padding: 2px 3px;
  border-radius: 99px;
  cursor: pointer;
  width: 170px;
}

.orange-btnn {
  background: #f8a401;
  color: #fff;
  text-align: center;
  padding: 2px 3px;
  border-radius: 99px;
  white-space: nowrap;
  cursor: pointer;
  width: 150px;
}

.red-btnn {
  background: red;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  padding: 2px 3px;
  border-radius: 99px;
  cursor: pointer;
  width: 170px;
}

.is-disabled {
  pointer-events: none;
  font-weight: 700;
  text-transform: capitalize !important;
}
.purple-btnn {
  background: #382d8b;
  color: #fff;
  border-radius: 99px;
  white-space: nowrap;
  cursor: pointer;
  padding: 2px 3px;
  width: 150px;
  text-align: center;
}
.green-btnn {
  background: #00a859;
  color: #fff;
  border-radius: 99px;
  white-space: nowrap;
  cursor: pointer;
  padding: 2px 3px;
  width: 170px;
  text-align: center;
}
td {
  padding: 18px 41px;
  text-align: center;
  font-size: 14px;
  white-space: nowrap !important;
}

th {
  padding: 18px 41px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap !important;
  text-align: center !important;
  color: #9aa2ac !important;
}

.display-arrow {
  display: none !important;
}

.table {
  border: 0;
  overflow-x: scroll;
}

@media only screen and (max-width: 600px) {
  .display-arrow {
    display: block !important;
  }
}

@media only screen and (max-width: 920px) {
  td {
    padding: 15px 20px !important;
    text-align: center;
    font-size: 13px;
    white-space: nowrap !important;
  }

  .info-btnn {
    background: #0163de;
    color: #fff;
    border-radius: 99px;
    white-space: nowrap;
    text-align: center;
    padding: 2px 10px;
    width: 100%;
    white-space: nowrap;
    font-size: 12px;
  }
  .orange-btnn {
    background: #f8a401;
    color: #fff;
    text-align: center;
    border-radius: 99px;
    white-space: nowrap;
    padding: 2px 4px;
    width: 90px;
  }
  .green-btnn {
    background: #00a859;
    color: #fff;
    border-radius: 99px;
    white-space: nowrap;
    cursor: pointer;
    padding: 2px 3px;
    width: 90px;
    text-align: center;
  }
  .purple-btnn {
    background: #382d8b;
    color: #fff;
    white-space: nowrap;
    padding: 2px 3px;
    border-radius: 99px;
    width: 90px;
    text-align: center;
  }

  th {
    padding: 15px 20px !important;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    white-space: nowrap !important;
    white-space: nowrap;
    text-align: center !important;
    color: #9aa2ac !important;
  }
}
