.col-profile-box {
  left: 166px;
  top: 150px;
  width: 83%;
  margin-bottom: 2rem;
  border: 1px solid #d6d6c2;
  border-radius: 19px;
}

.move-top{
  margin-top: -.5rem !important;
}

.date-input-top{
  margin-top: 1rem;
}

.is-disabled {
  pointer-events: none  !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.flex-action-profile{
  display:flex;
  width: 100%;
  justify-content:space-between;
  justify-content: center;
}

.validate-er{
  color: red;
  font-size: 13px;
}

.title-summary-val {
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  color: #333333;
}

.success-box {
  left: 166px;
  top: 150px;
  height: 400px;
  width: 50%;
  margin-bottom: 3rem;
  background: #ffffff;
  box-shadow: 0px 3px 5px 3px rgba(163, 171, 185, 0.36);
  border-radius: 20px;
}

.label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  /* identical to box height */
  color: #575a5f;
}

.lead-p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 48px;
  /* identical to box height */

  color: #292d34;
}

.input-profile {
  height: 47px;
  border: 1px solid #e9ebf0 !important;
  border-radius: 14.5234px;
}

.-top-space{
  margin-top: 0 !important;
}

.-top-space-level{
  margin-top: 0 !important;
}

@media only screen and (max-width: 920px) {
  .success-box {
    left: 166px;
    top: 150px;
    height: 400px;
    width: 100%;
    margin-bottom: 3rem;
    background: #ffffff;
    box-shadow: 0px 3px 5px 3px rgba(163, 171, 185, 0.36);
    border-radius: 20px;
  }

  .move-top{
    margin-top: 1.2rem !important;
  }
  .col-profile-box {
    left: 166px;
    top: 150px;
    width: 100%;
    margin-bottom: 2rem;
    border: 1px solid #d6d6c2;
    border-radius: 19px;
  }
  .flex-action-profile{
    display:block;
   
  }
  .-top-space{
    margin-top: 1.2rem !important;
  }
  .-top-space-level{
    margin-top: 2.2rem !important;
  }
}
