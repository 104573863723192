/*----------

24. zoom-class style

----------*/

@use "../abstract" as *;

// zoom-class stle
.zoom-class-main-wrapper {
	display: flex;
	border-radius: 8px;
	overflow: hidden;
	@media #{$sm,$xs} {
		display: block;
	}
}
.zoom-class-thumb {
	width: 48%;
	overflow: hidden;
	@media #{$sm,$xs} {
		width: 100%;
	}
	img {
		object-fit: cover;
		height: 100%;
		transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
	}
}
.zoom-class-main-wrapper:hover .zoom-class-thumb img {
	transform: scale3d(1.1, 1.1, 1.1);
}
.zoom-class-item {
	padding: 28px 30px 29px 30px;
	background: $white;
	box-shadow: 0px 5px 20px rgba(12, 29, 62, 0.05);
	border-bottom: 1px solid $border;
	border-right: 1px solid $border;
	border-top: 1px solid $border;
	width: 52%;
	border-radius: 0 8px 8px 0;
	@media #{$xs,$sm} {
		border-left: 1px solid $border;
		border-top: none;
		border-radius: 0 0 8px 8px;
		padding: 28px 20px 29px 20px;
		width: 100%;
	}
}
.zooom-class-tittle {
	h3 {
		line-height: 1.5;
		margin-bottom: 27px;
		@media #{$lg,$md,$sm,$xs} {
			font-size: 20px;
		}
		a:hover {
			color: $theme-color;
		}
	}
	@media #{$lg} {
		font-size: 20px;
	}
}
.class-metting {
	margin-top: 20px;
	display: block;
	a {
		font-weight: 700;
		color: $theme-color;
		margin-left: 10px;
	}
}
.class-list-date {
	margin-bottom: 5px;
	i {
		padding-right: 10px;
		line-height: 1;
	}
}
.class-list-length {
	margin-bottom: 5px;
	i {
		padding-right: 10px;
		line-height: 1;
	}
}
.class-list-time {
	margin-bottom: 5px;
	i {
		padding-right: 10px;
		line-height: 1;
	}
}
// zoom-class-detalies style
.sidebar-widget-details {
	padding: 30px;
	box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
	border: 1px solid $border;
	background: $white;
	border-radius: 5px;
}
.widget-detalis {
	display: flex;
	align-items: center;
}

.zoom-widget-tittle {
	h4 {
		font-size: 20px;
	}
}
.zoom-widget-list {
	padding: 30px 0;
}
.zoom-widget-list {
	ul {
		li {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid $border;
			padding: 10px 0px 7px 0px;
		}
		i {
			line-height: 1;
			margin-right: 10px;
		}
	}
}
.widget-detalis {
	span {
		font-weight: 600;
		color: $heading-color;
	}
}
.zoom-btn.text-center {
	i {
		line-height: 1;
		margin-right: 10px;
	}
}
.zoom-btn {
	i {
		line-height: 0;
		margin-right: 10px;
		font-size: 20px;
	}
}
.content-main-heading {
	a {
		font-size: 15px;
		font-weight: 600;
		background: #6f19c5;
		padding: 0px 10px;
		line-height: 25px;
		color: $white;
		border-radius: 5px;
		margin-bottom: 20px;
		display: inline-block;
	}
	h2 {
		margin-bottom: 30px;
		@media #{$lg,$md} {
			font-size: 32px;
		}
		@media #{$sm,$xs} {
			font-size: 24px;
		}
	}
}
.contents-widget {
	p {
		margin-bottom: 30px;
	}
	h4 {
		font-size: 18px;
		margin-bottom: 15px;
	}
}
.objective-list {
	ul {
		margin-bottom: 35px;
	}
}
// zoom-clender-style
.sidebar-widget-wrapper {
	margin-left: 30px;
	@media #{$lg,$md,$xs,$sm} {
		margin-left: 0;
	}
}
.event-detalis-area .sidebar-widget-wrapper {
	@media #{$lg} {
		margin-left: 0;
	}
}
.sidebar-widget {
	border: 1px solid $border;
	border-radius: 5px;
	box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
	background-color: $white;
	max-width: 510px;
	@media #{$xs} {
		max-width: 100%;
	}
}
.sidebar-widget-inner {
	padding: 35px 30px 30px 30px;
	height: 385px;
}

//calender style start
.react-calendar {
	width: 350px;
	max-width: 100%;
	border: 0 !important;
	font-family: "Raleway", sans-serif !important;

	abbr[title], abbr[data-bs-original-title] {
		text-decoration:  none !important;
	}
	.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
		display: none;
	}
	.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
		display: none;
	}
}
.react-calendar__navigation {
	background: #f4f4f6 !important;
}
