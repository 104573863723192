/*----------

20. about style

----------*/

@use "../abstract" as *;

// about-style
.about-shape {
	position: absolute;
	top: 15%;
	right: 2%;
	@media #{$xs,$md} {
		display: none;
	}
}
.about-shape-1 {
	position: absolute;
	top: 0;
	left: 0;
}
.about-shape-2 {
	position: absolute;
	top: 5%;
	left: 6%;
	z-index: -11;
}
.about-shape-3 {
	position: absolute;
	top: 5%;
	left: -30px;
	z-index: -11;
}
.about-main-img {
	padding-left: 108px;
}
.about-img img {
	max-width: 100%;
}
.affiliates-wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 60px 140px;
}
// content-error style
.content-error-area {
	border-top: 1px solid #edeef2;
}
.error-thumb {
	img {
		margin-bottom: 40px;
		max-width: 100%;
	}
}
.error-btn {
	margin-top: 35px;
}

// rating-star style
.rating-star {
	i {
		color: #ccc;
	}
}
.rating-star {
	i {
		font-size: 13px;
	}
	i.fas {
		color: $theme-color-2;
	}
}
.know-us-border {
	border-top: 1px solid $border;
}

// singup style

.signin-area-wrapper {
	display: flex;
}
.sign-up-wrapper {
	display: flex;
	width: 100%;
	display: inline-block;
}
.signup-wrapper {
	input {
		width: 100%;
		height: 55px;
		background: #f5f5f5;
		border: 0;
		border-radius: 4px;
		margin-bottom: 30px;
		display: flex;
		padding: 10px 20px;
		outline: none;
		color: $body-text;
		font-size: 16px;
	}
}
.signup-input-wrapper {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	@media #{$xs,$sm,$md} {
		display: inherit;
	}
}
.signup-input-wrapper {
	input {
		width: 100%;
		height: 55px;
		background: #f5f5f5;
		border: 0;
		border-radius: 4px;
		margin-bottom: 30px;
		display: flex;
		padding: 10px 20px;
		outline: none;
		color: $body-text;
		font-size: 16px;
	}
}
.sign-check {
	a {
		color: $theme-color;
		font-weight: 700;
		text-decoration: underline;
		&:hover {
			color: $heading-color;
		}
	}
}
.signup-box {
	background: #f5f5f5;
	padding: 45px 30px;
}
.signup-thumb {
	img {
		max-width: 100%;
	}
}
.signup-form-wrapper {
	padding: 45px 30px;
	background: $white;
}
.signup-action {
	margin-bottom: 25px;
}
.sing-buttom {
	height: 55px;
	background: $theme-color;
	text-align: center;
	line-height: 55px;
	color: $white;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 600;
	transition: 0.3s;
	&:hover {
		color: $heading-color;
		background: $theme-color-2;
	}
}
.registered.wrapper {
	display: flex;
	justify-content: space-around;
	margin-bottom: 20px;
	flex-wrap: wrap;
}
.forget-password a {
	&:hover {
		color: $heading-color;
	}
}
.not-register a {
	&:hover {
		color: $heading-color;
	}
}
.acount-login {
	margin-bottom: 30px;
	a {
		font-weight: 600;
		color: $theme-color;
		margin-left: 5px;
		text-decoration: underline;
		&:hover {
			color: $heading-color;
		}
	}
}
.sign-social {
	position: relative;
	z-index: 2;
	margin-bottom: 25px;
	span {
		background: $white;
		display: inline-block;
		padding: 0 10px;
	}
	&:before {
		position: absolute;
		height: 1px;
		background: $border;
		content: "";
		width: 100%;
		top: 15px;
		left: 0;
		z-index: -1;
	}
}
.sign-social-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 19px;
}
.signup-text {
	h3 {
		font-size: 28px;
		margin-bottom: 106px;
	}
}
.signup-message {
	img {
		max-width: 100%;
		margin-bottom: 5px;
	}
}
.not-register {
	a {
		font-weight: 600;
		color: $theme-color;
		text-decoration: revert;
		margin-left: 5px;
	}
}
.sign-gmail {
	border: 1px solid $border;
	padding: 10px 20px;
	border-radius: 4px;
	a {
		font-weight: 600;
		&:hover {
			color: $heading-color;
		}
	}
    svg {
        height: 20px;
        margin-right: 5px;
        transform: translateY(-2px);
    }
}
.sign-facebook {
	border: 1px solid $border;
	padding: 10px 20px;
	border-radius: 4px;
	a {
		font-weight: 600;
		&:hover {
			color: $heading-color;
		}
	}
    svg {
        height: 20px;
        margin-right: 5px;
        transform: translateY(-2px);
    }
}
.signin-area {
	position: fixed;
	z-index: 1008;
	top: 60px;
	margin: 0 auto;
	left: 0;
	top:130px;
	right: 0;
	display: none;
	max-width: 900px;
	@media #{$md,$sm,$xs} {
		max-width: 360px;
	}
}
.profilemodal-area {
	position: fixed;
	z-index: 1008;
	top: 60px;
	margin: 0 auto;
	left: 0;
	top:150px;
	right: 0;
	display: none;
	max-width: 50px;
	@media #{$md,$sm,$xs} {
		max-width: 360px;
	}
}
.signin-area.open {
	display: inline-block;
}
.signin-area-wrapper > :nth-child(2) {
	flex: 0 0 calc(100% - 360px);
}
@media (min-width: 992px) and (max-width: 1199px) {
	.signin-area-wrapper > *:nth-child(1) {
		flex: 0 0 360px;
	}
	.signin-area-wrapper > :nth-child(2) {
		flex: 0 0 calc(100% - 360px);
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.signin-area-wrapper {
		max-width: 750px;
		max-height: 530px;
		transform: none;
		overflow: auto;
		overflow-y: auto;
		display: inline-block;
	}
	.signin-area.open {
		display: flex;
		justify-content: center;
	}
	.signin-area-wrapper > * {
		flex: 0 0 100% !important;
	}
}
@media (max-width: 767px) {
	.signin-area-wrapper {
		max-width: 750px;
		max-height: 530px;
		transform: none;
		overflow: auto;
		overflow-y: auto;
		display: inline-block;
	}
	.signin-area.open {
		display: flex;
		justify-content: center;
	}
	.signin-area-wrapper > * {
		flex: 0 0 100% !important;
	}
}
// signup
.signup-area {
	position: fixed;
	z-index: 99999;
	top: 60px;
	margin: 0 auto;
	left: 0;
	top:130px;
	right: 0;
	display: none;
	max-width: 900px;
	@media #{$md,$sm,$xs} {
		max-width: 360px;
	}
}
.signup-area.open {
	display: inline-block;
	@media #{$xs,$sm,$md} {
		display: flex;
		justify-content: center;
	}
}
.sign-up-wrapper > :nth-child(2) {
	flex: 0 0 calc(100% - 360px);
}
@media (min-width: 992px) and (max-width: 1199px) {
	.sign-up-wrapper > *:nth-child(1) {
		flex: 0 0 360px;
	}
	.sign-up-wrapper > :nth-child(2) {
		flex: 0 0 calc(100% - 360px);
	}
}
.sign-up-wrapper {
	@media #{$md,$xs,$sm} {
		display: flex;
		max-width: 750px;
		max-height: 530px;
		transform: none;
		overflow: auto;
		overflow-y: auto;
		overflow-y: auto;
		display: inline-block;
	}
}