/*----------

08. testimonial style

----------*/

@use "../abstract" as *;

// testimonisl-style

.testimonial-header {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.testimonial-items {
	background: #f8f9fb;
	padding: 30px;
	z-index: 2;
	border-radius: 5px;
	min-height: 100%;
}

.testimonial-img {
	img {
		margin-right: 20px;
	}
}

.testimonial-title {
	h4 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 2px;
	}

	span {
		font-size: 16px;
		margin-bottom: 2px;
		display: block;
	}
}

.testimoni-quotes {
	img {
		position: absolute;
		top: 30px;
		right: 30px;
		z-index: -1;
		content: "";
	}
}

.testimonial-body {
	margin-bottom: 20px;

	h3 {
		font-size: 20px;
		color: $theme-color;
		margin-bottom: 20px;
	}
}

.testimonial-icon {
	svg {
		color: $theme-color-2;
		font-size: 15px;
	}
}

.testimonial-pagination {
	margin-top: 50px;
	line-height: 1;
}