body {
  margin: 0;
  font-family: Poppins;
}

code {
  font-family: Poppins;
}

*input{
border: none !important;
outline: 1px solid #B7B7B7 !important;
outline-color: none !important;

}

*input:focus{
  outline: border !important;
  border: 1px solid #B7B7B7 !important;
  outline-color: none !important;
}

.faq-head {
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  margin-top: -2rem;
  margin-bottom: 2.4rem;
  text-align: left;
  display: flex;
  align-items: center;

  color: #333333;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  padding-right: 3em;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 3rem !important;
  /* identical to box height, or 22px */

  color: #333333 !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  color: var(--row-content-color, black);
  font-size: var(--row-content-text-size, medium);
  padding: var(--row-content-padding-top, 0) var(--row-content-padding-right, 0)
    var(--row-content-padding-bottom, 0) var(--row-content-padding-left, 0);
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 140%;
  color: rgba(60, 60, 67, 0.85) !important;
}

.pol-head {
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  margin-top: -2rem;
  margin-bottom: 2;
  text-align: center;
  color: #333333;
}

.hrr {
  border-bottom: 1px solid #e5e5e5;
  margin-top: 2rem;
}

@media screen and (max-width: 960px) {
  .faq-head {
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    margin-top: -1.5rem;
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
  }
  .styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  padding-right: 3em;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 1.5rem !important;
  /* identical to box height, or 22px */

  color: #333333 !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  color: var(--row-content-color, black);
  font-size: var(--row-content-text-size, medium);
  padding: var(--row-content-padding-top, 0) var(--row-content-padding-right, 0)
    var(--row-content-padding-bottom, 0) var(--row-content-padding-left, 0);
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 100%;
  color: rgba(60, 60, 67, 0.85) !important;
}


  .pol-head {
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    margin-top: 3rem;
    margin-bottom: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #333333;
  }

  .faq-p {
    margin-bottom: 4rem;
  }
}
