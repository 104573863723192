.stripe-padon {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.order-check-border {
  background: #ffffff;
  box-shadow: 0px 3px 5px 3px rgba(163, 171, 185, 0.36);
  border-radius: 20px;
  padding: 100px;
}

@media only screen and (max-width: 920px) {
  .order-check-border {
    background: #ffffff;
    box-shadow: 0px 3px 5px 3px rgba(163, 171, 185, 0.36);
    border-radius: 20px;
    padding: 30px;
  }
}
