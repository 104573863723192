.container-custom-new {
  padding: 0 70px;
}

.ant-carousel .slick-dots li button::after {
  position: absolute;
  inset: -4px;
  content: "";
  background: #382d8b !important;
}

.alice-carousel__dots {
  /* margin: 30px 3px 5px; */
  padding: 0;
  list-style: none;
  text-align: center;
  display: none !important;
  margin-top: -2rem !important;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #ffb013 !important;
}

option:disabled {
  opacity: 0.6;
  background-color: #ff888f !important;
}

.ant-btn-dangerous {
  color: #fff !important;
  border-color: #382d8b !important;
  background: #382d8b !important;
  cursor: pointer !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
  display: flex !important;
  justify-content: space-between !important;
}

.ant-btn-dangerous:hover {
  color: #fff !important;
  border-color: #ffb013 !important;
  background: #ffb013 !important;
  cursor: pointer !important;
}

/* input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  -webkit-appearance: none !important;
  border: none !important;
}

input:focus[type="text"],
input:focus[type="password"],
input:focus[type="number"],
textarea {
  -webkit-appearance: none !important;
  border: none !important;
  outline: none !important;
} */

/* select {
  height: 50px;
  background: #ffffff !important;
  width: 100%;
  border: 0.899471px solid #b7b7b7;
  border-radius: 0.960832px;
  outline-color: #b7b7b7;
  outline: 1px solid #b7b7b7;
  font-weight: 500;
  font-size: 14px;
} */

.cursor {
  cursor: pointer !important;
}

.edit-fill {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box !important;
  border: none !important;
  border-bottom: 2px solid red !important;
}

.dark-col {
  color: #333333 !important;
  line-height: 33px;
  font-weight: 700 !important;
}

.primary-col {
  font-size: 16.1905px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #382d8b;
}

.review-order-page {
  height: 100%;
  margin-bottom: 20rem !important;
}

body {
  font-family: Poppins;

}

.hero-height {
  height: 600px !important;
  margin-bottom: 5rem !important;
}

.border-review-part {
  width: 100%;
  overflow-y: scroll;
  height: 300px;
  border: solid;
  padding: 20px;
  margin-right: 20px;
}

.btn-up {
  margin-top: -0.1rem !important;
}

.btn-start {
  height: 55px;
  background: #382d8b;
  text-align: center;
  line-height: 55px;
  margin-top: -0.7rem;
  color: white;
  width: 27%;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s;
}

.hero-quote {
  padding-top: 0.7rem !important;
  color: grey !important;
}

.privacy-policy-text {
  font-size: 13px;
  font-weight: 300;
}

.privacy-header {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
  margin-top: 2rem;
}

.ant-modal-close {
  position: absolute;
  top: 2px;
  right: 10px;
  z-index: 15;
  padding: 0;
  display: none !important;
  margin: 1.5rem;
  color: white !important;
  font-weight: 700;
  border: 1px solid white !important;
  border-radius: 25% !important;
  background: #b22222 !important;
  line-height: 1;
  text-decoration: none;
  outline: none !important;
  outline-color: #000 I !important;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-modal-close-x {
  display: block;
  width: 40px !important;
  height: 50px !important;
  font-size: 16px !important;
  font-style: normal;
  margin-top: -1.2rem;
  line-height: 56px;
  color: white !important;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

label {
  font-weight: 500;
}

body {
  font-size: 16px;
  font-family: Poppins !important;
}

textarea {
  background: white !important;
  border: 1px solid #2467ec !important;
  border-radius: 10px !important;
}

.active-route {
  color: #ffb013;
}

a.link-hover span {
  color: #382d8b;
}

.div-button-request:hover {
  background-color: #ffb013;
}

a.link-hover:hover {
  color: #ffb013;
}

a.link-hover span:hover {
  color: #ffb013;
}

.hero-right-img img {
  width: 100%;
  margin-top: 4rem;
}

.contact-us-text {
  cursor: pointer;
}

.contact-us-text:hover {
  color: #ffb013;
}

.team-rows {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.reviews-main {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.order-num {
  color: #382d8b;
  font-weight: 500;
  font-size: 18px;
}

.team-rows img {
  max-width: 27%;
  margin-right: 2rem;
}

.padding1 h5 {
  padding-top: 40px;
}

.padding2 h5 {
  padding-top: 140px;
}

.col-xl-6 strong {
  font-weight: 700;
  color: #382d8b;
}

.card-ans {
  background: #d3d3d3;
  font-family: italic;
}

.team-rows h5 {
  font-weight: 600;
}

.container-padding {
  padding-left: 100px;
  padding-right: 100px;
}

.web-view {
  display: block;
}

.mobile-view {
  display: none;
}

.top-margin {
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.container-padding-min {
  padding-left: 0;
  padding-right: 0;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

.checkout-form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.checkout-button {
  background: #5469d4;
  font-family: Poppins;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.checkout-button:hover {
  filter: contrast(115%);
}

.checkout-button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cursor {
  cursor: pointer !important;
}

@media only screen and (max-width: 600px) {
  .checkout-form {
    width: 80vw;
    min-width: initial;
  }

  .container-custom-new {
    padding: 0px 20px !important;
  }

  .review-order-page {
    height: 100vh;
    margin-bottom: 30rem !important;
  }

  .btn-start {
    height: 55px;
    background: #382d8b;
    text-align: center;
    line-height: 55px;
    color: white;
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    transition: 0.3s;
  }
}

.mobile-space {
  padding-top: 10rem;
}

@media only screen and (min-width: 768px) and (max-width: 994px) {
  .hero-right-img {
    width: 100% !important;
    margin-top: 10rem;
    height: 100% !important;
  }

  .review-order-page {
    height: 100vh;
    margin-bottom: 30rem !important;
  }

  .hero-height {
    height: 750px !important;
  }

  .mobile-space {
    padding-top: 9rem;
  }

  .btn-start {
    height: 55px;
    background: #382d8b;
    text-align: center;
    line-height: 55px;
    color: white;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    transition: 0.3s;
  }

  .team-rows {
    display: flex;
    flex-direction: column;
  }

  .mobile-view {
    display: block;
  }

  .video {
    margin-top: 3rem;
  }

  .web-view {
    display: none;
  }

  .reviews-main {
    display: flex;
    flex-direction: column;
  }

  .container-padding {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: -1rem;
  }

  .hero-right-img img {
    width: 100%;
    margin-top: 2rem;
  }

  .team-rows img {
    max-width: 40%;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .padding1 h5 {
    padding-top: 0px;
  }

  .padding2 h5 {
    padding-top: 0px;
  }

  .team-rows h5 {
    font-weight: 600;
  }

  .top-margin {
    padding-top: 4rem;
  }

  .container-padding-min {
    padding-left: 30px;
    padding-right: 30px;
  }
}

/*Teams*/
@media screen and (max-width: 768px) {
  .team-rows {
    display: flex;
    flex-direction: column;
  }

  .review-order-page {
    height: 100vh;
    margin-bottom: 30rem !important;
  }

  .border-review-part {
    width: 100%;
    overflow-y: scroll;
    margin-top: -5rem;
    height: 100%;
    border: 1px solid black;
    padding: 20px;
    margin-top: -3rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .student-choose-area {
    margin-top: -0.1rem;
  }

  .btn-start {
    height: 55px;
    background: #382d8b;
    text-align: center;
    line-height: 55px;
    color: white;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 16px;
    font-weight: 600;
    transition: 0.3s;
  }

  .mobile-view {
    display: block;
  }

  .video {
    margin-top: 3rem;
  }

  .web-view {
    display: none;
  }

  .reviews-main {
    display: flex;
    flex-direction: column;
  }

  .container-padding {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: -4rem;
  }

  .hero-height {
    height: 100% !important;
  }

  .hero-right-img img {
    width: 100%;
    margin-top: 2rem;
  }

  .team-rows img {
    max-width: 60%;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .padding1 h5 {
    padding-top: 0px;
  }

  .padding2 h5 {
    padding-top: 0px;
  }

  .team-rows h5 {
    font-weight: 600;
  }

  .top-margin {
    padding-top: 4rem;
    padding-bottom: 0;
  }

  .mobile-space {
    margin-top: 4rem;
    margin-bottom: 6rem;
  }

  .mobile-space-reviews {
    margin-top: 9rem;
    margin-bottom: 6rem;
  }

  .container-padding-min {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.show-web {
  display: block;
}

/* font-family: "Source Sans Pro",Arial,sans-serif; */