/*----------

03. hero style

----------*/

@use "../abstract" as *;

// hero-style

.hero-text {
	h5 {
		color: $theme-color;
		font-size: 18px;
		margin-bottom: 25px;
	}

	h2 {
		line-height: 1.2;
		margin-bottom: 30px;
		font-size: 50px;

		@media #{$lg,$md} {
			font-size: 40px;
		}

		@media #{$xl} {
			font-size: 30px;
		}

		@media #{$lg} {
			font-size: 35px;
		}

		@media #{$md} {
			font-size: 27px;
		}

		@media #{$xs,$sm,$md} {
			font-size: 30px;
		}
	}

	p {
		margin-bottom: 40px;
		font-size: 18px;
	}
}

.hero-quote {
	display: flex;
	justify-content: center;
	margin-top: 180px;

	@media #{$md,$sm,$xs} {
		margin-top: 200px;
	}
}

.hero-height {
	height: 750px;
	background: white;
	background-size: cover;
	background-repeat: no-repeat;
	color: #222 !important;

	@media #{$md} {
		height: 540px !important;
		background-position: 80% 0%;
	}

	@media #{$sm,$xs} {
		height: 950px;
		background-position: inherit;
	}

	display: flex;
	align-items: center;
	background-position: center;

	@media #{$xxl,$xl,$lg} {
		background-position: 80% center;
	}
}

.section-title {
	h2 {
		font-size: 36px;
		line-height: 1.3;

		@media #{$lg} {
			font-size: 30px;
		}

		@media #{$md} {
			font-size: 30px;
		}

		@media #{$sm} {
			font-size: 28px;
		}

		@media #{$xs} {
			font-size: 26px;
		}

		a {
			color: $theme-color;
		}
	}
}

// shape-style

.shape.shape-1 {
	position: absolute;
	-webkit-animation: hero-circle-1 6s linear 0s infinite alternate;
	-moz-animation: hero-circle-1 6s linear 0s infinite alternate;
	-o-animation: hero-circle-1 6s linear 0s infinite alternate;
	animation: hero-circle-1 6s linear 0s infinite alternate;
	top: 35px;
	left: 105px;

	@media #{$xxl} {
		left: -10%;
	}

	@media #{$md,$sm,$xs} {
		display: none;
	}
}

.shape-2 {
	position: absolute;
	top: 100px;
	right: -25%;
	z-index: 1;

	@media #{$xxl,$xl} {
		right: 0;
	}

	@media #{$lg,$md,$sm,$xs} {
		display: none;
	}
}

.shape-3 {
	position: absolute;
	top: 50%;
	left: 0;
}

.shape-4 {
	position: absolute;
	top: 335px;
	left: 150px;

	@media #{$xxl} {
		top: 250px;
		left: -15px;
	}

	@media #{$xxxl} {
		top: 320px;
		left: 180px;
	}

	@media #{$xl} {
		top: 200px;
		left: 6%;
	}

	@media #{$lg} {
		top: 274px;
		left: 0px;
	}

	@media #{$md,$sm,$xs} {
		display: none;
	}

	border-radius: 12px;
	background: $white;
	box-shadow: 0px 40px 50px rgba(28, 51, 84, 0.16);
	width: 180px;
	padding: 17px;
	text-align: center;

	img {
		box-shadow: 0px 10px 20px rgba(24, 44, 74, 0.16);
		border-radius: 50%;
		margin-bottom: 20px;
	}

	h5 {
		line-height: 1.6;
		font-size: 18px;
		font-weight: 700;
		font-family: "Nunito Sans";
		color: $heading-color;
	}
}

.shape-5 {
	-webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	-moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	-o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	position: absolute;
	max-width: 283px;
	box-shadow: 0px 40px 50px rgba(28, 51, 84, 0.16);
	bottom: -350px;
	right: -36%;
	padding: 20px 24px 15px;
	background: $white;
	border-radius: 12px;

	@media #{$xxxl} {
		right: -15%;
	}

	@media #{$xxl} {
		bottom: -300px;
		right: 0%;
	}

	@media #{$xl,$lg} {
		right: -7%;
	}

	@media #{$md} {
		bottom: -217px;
		right: -10%;
	}

	@media #{$md,$sm,$xs} {
		display: none;
	}

	h5 {
		font-size: 18px;
		line-height: 1.6;
		font-weight: 400;
		font-family: "Nunito Sans";
		margin-bottom: 0;
	}

	span {
		color: $theme-color;
		font-weight: 700;
		text-align: center;
	}
}

.shape-6 {
	position: absolute;
	top: 200px;
	left: 17%;

	@media #{$xxxl} {
		right: 0;
	}

	@media #{$md,$sm,$xs} {
		display: none;
	}
}

.course-card {
	position: absolute;
	top: -31px;
	left: 15px;
	background: $white;
	padding: 10px 15px;
	border-radius: 7px;

	img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		box-shadow: 0px 0px 10px rgba(28, 51, 84, 0.1);
		margin-right: -15px;
		border: 4px solid $white;
	}

	span {
		i {
			width: 30px;
			height: 30px;
			background: $theme-color-2;
			border-radius: 50%;
			color: $heading-color;
			font-size: 10px;
			border: 4px solid $white;
			line-height: 22px;
		}
	}
}

// hero-area two

.header-home-2-area {
	@media #{$sm,$md} {
		padding: 10px 0;
	}
}

.hero-text {
	h2 {
		line-height: 1.2;
		margin-bottom: 20px;
		font-size: 50px;

		@media #{$xl} {
			font-size: 45px;
		}

		@media #{$lg} {
			font-size: 38px;
		}

		@media #{$md,$sm} {
			font-size: 36px;
		}

		@media #{$xs} {
			font-size: 30px;
		}
	}
}

.hero-area {
	height: 750px;
	background-color: #edeff5;
}

.hero-thumb-01 {
	position: absolute;
	left: -170px;
	top: -90px;

	@media #{$xxxl} {
		left: -115px;
		top: -75px;
	}

	@media #{$xxl,$xl} {
		left: -1%;
		top: -75px;
	}
}

.hero-thumb-02 {
	position: absolute;
	top: -60px;
	right: -145px;

	@media #{$xxl} {
		right: -50px;
	}

	@media #{$xl,$lg} {
		right: -3%;
	}
}

.hero-tittle-info {
	@media #{$xxl} {
		margin-left: 10%;
	}

	h2 {
		font-size: 50px;

		@media #{$xxl,$xl} {
			font-size: 45px;
		}

		@media #{$lg} {
			font-size: 38px;
		}

		@media #{$md,$sm} {
			font-size: 36px;
		}

		@media #{$xs} {
			font-size: 30px;
		}
	}
}

.slider-search-icon input {
	width: 100%;
	height: 70px;
	background: $white;
	outline: none;
	border: 1px solid $border;
	border-radius: 5px;
	padding: 0px 40px 0 30px;
}

.slider-search {
	margin: 0 auto;
	margin-bottom: 30px;

	@media #{$xxxl} {
		width: 80%;
	}

	@media #{$xxl,$xl} {
		width: 70%;
	}
}

.slider-content-wrapper {
	@media #{$xxl} {
		text-align: center;
	}
}

.slider-search-icon button {
	position: absolute;
	top: 50%;
	right: 23px;
	transform: translatey(-50%);
	color: $theme-color;
}

.slider-course-content {
	ul {
		li {
			display: inline-block;
			margin: 0 20px;
			margin-bottom: 10px;

			@media #{$xl} {
				margin: 0 10px;
			}

			i {
				margin-right: 10px;
				color: #b9c4d6;
				font-size: 20px;
				transform: translateY(2px);
			}

			span {
				font-size: 16px;
				font-weight: 600;
				color: $heading-color;
			}
		}
	}
}

.slider-content-wrapper {
	z-index: 1;
	position: relative;
}

// hero-2 sahpe
.hero-shape-2 {
	position: absolute;
	top: -42%;
	left: -3%;
	-webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	-moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	-o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	animation: hero-thumb-sm-2-animation 4s linear infinite alternate;

	@media #{$md,$sm,$xs} {
		display: none;
	}

	@media #{$xxl} {
		left: 4%;
	}
}

.hero-shape-1 {
	position: absolute;
	bottom: 9%;
	z-index: 1;
	left: 0;

	@media #{$lg,$md,$sm,$xs} {
		display: none;
	}
}

.hero-shape-3 {
	position: absolute;
	left: -60px;
	border-radius: 12px;
	background: $white;
	box-shadow: 0px 40px 50px rgba(28, 51, 84, 0.16);
	width: 180px;
	padding: 17px;
	text-align: center;
	bottom: -20px;
	z-index: 1;
	height: 200px;

	@media #{$md} {
		bottom: -174px;
	}

	@media #{$xxl,} {
		left: 52px;
		top: 160px;
	}

	@media #{$xl,} {
		left: -6%;
		top: 40%;
	}

	@media #{$md,$sm,$xs} {
		display: none;
	}

	h5 {
		font-size: 18px;
		line-height: 28px;
	}

	img {
		box-shadow: 0px 10px 20px rgba(24, 44, 74, 0.16);
		border-radius: 50px;
		margin-bottom: 15px;
	}
}

.hero-shape-4 {
	position: absolute;
	top: 60px;
	z-index: 1;
	right: 21%;

	@media #{$xxl} {
		top: 50px;
		right: 25%;
	}
}

.hero-shape-5 {
	position: absolute;
	right: 11%;
	top: 9%;
}

.hero-shap-5 {
	position: absolute;
	max-width: 283px;
	box-shadow: 0px 40px 50px rgba(28, 51, 84, 0.16);
	bottom: 20%;
	right: 9%;
	-webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	-moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	-o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	padding: 25px 20px 16px 20px;
	background: $white;
	border-radius: 12px;
	z-index: 1;

	@media #{$xxl} {
		right: 6%;
	}

	@media #{$lg,$md,$sm,$xs} {
		display: none;
	}

	h5 {
		font-size: 18px;
		line-height: 28px;
		font-family: "Nunito Sans", sans-serif;
		margin-bottom: 0px;
		font-weight: 400;
	}

	span {
		color: $theme-color;
		font-weight: 700;
		text-align: center;
	}
}

.hero-shape-6 {
	position: absolute;
	bottom: 7%;
	right: 21%;
	-webkit-animation: hero-circle-1 6s linear 0s infinite alternate;
	-moz-animation: hero-circle-1 6s linear 0s infinite alternate;
	-o-animation: hero-circle-1 6s linear 0s infinite alternate;
	animation: hero-circle-1 6s linear 0s infinite alternate;
}

.hero-shape-7 {
	position: absolute;
	bottom: 0;
	right: 46%;

	@media #{$md,$sm,$xs} {
		display: none;
	}
}

.hero-card {
	background: $white;
	padding: 10px 10px;
	border-radius: 7px;
	position: absolute;
	bottom: 75%;

	img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		box-shadow: 0px 0px 10px rgba(28, 51, 84, 0.1);
		margin-right: -15px;
		border: 4px solid $white;
	}

	span {
		i {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			line-height: 22px;
			width: 30px;
			background: $theme-color-2;
			border-radius: 50%;
			color: $heading-color;
			font-size: 10px;
			border: 4px solid $white;
			line-height: 22px;
		}
	}
}

.skill-price-start {
	position: absolute;
	font-size: 16px;
	color: $white;
	background: red;
	height: 100px;
	width: 100px;
	line-height: 100px;
	border-radius: 50%;
	text-align: center;
	top: 0;
	left: 0;
}

// animation
@keyframes hero-thumb-sm-animation {
	0% {
		-webkit-transform: translateY(-20px) translateX(50px);
		-moz-transform: translateY(-20px) translateX(50px);
		-ms-transform: translateY(-20px) translateX(50px);
		transform: translateY(-20px) translateX(50px);
	}

	100% {
		-webkit-transform: translateY(-20px) translateX(0px);
		-moz-transform: translateY(-20px) translateX(0px);
		-ms-transform: translateY(-20px) translateX(0px);
		transform: translateY(-20px) translateX(0px);
	}
}

@keyframes hero-thumb-sm-2-animation {
	0% {
		-webkit-transform: translateY(-50px);
		-moz-transform: translateY(-50px);
		-ms-transform: translateY(-50px);
		transform: translateY(-50px);
	}

	100% {
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
}

@keyframes hero-circle-2 {
	0% {
		-webkit-transform: translateX(-200px);
		-moz-transform: translateX(-200px);
		-ms-transform: translateX(-200px);
		transform: translateX(-200px);
	}

	100% {
		-webkit-transform: translateX(0px);
		-moz-transform: translateX(0px);
		-ms-transform: translateX(0px);
		transform: translateX(0px);
	}
}

@keyframes hero-bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-30px);
	}

	60% {
		transform: translateY(-15px);
	}
}

@keyframes hero-circle-1 {
	0% {
		@include transform(rotate(0deg));
	}

	100% {
		@include transform(rotate(360deg));
	}
}

@keyframes hero-triangle-1 {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes services-triangle {
	0% {
		-webkit-transform: rotate(0deg) translateX(-50px);
		-moz-transform: rotate(0deg) translateX(-50px);
		-ms-transform: rotate(0deg) translateX(-50px);
		transform: rotate(0deg) translateX(-50px);
	}

	100% {
		-webkit-transform: rotate(360deg) translateY(100px);
		-moz-transform: rotate(360deg) translateY(100px);
		-ms-transform: rotate(360deg) translateY(100px);
		transform: rotate(360deg) translateY(100px);
	}
}

// hero-3-style
.hero-area-3 {
	position: relative;
	max-width: 1920px;
	margin: 0 auto;

	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		background: #010a2e;
		content: "";
		opacity: 0.45;
	}
}

.hero-university-text {
	span {
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
		color: $theme-color-2;
		margin-bottom: 35px;
		display: block;
	}

	h2 {
		font-size: 80px;
		color: $white;
		font-weight: 900;
		margin-bottom: 35px;
		line-height: 1.1;
		text-transform: uppercase;

		@media #{$xl} {
			font-size: 70px;
		}

		@media #{$md,$sm,$lg} {
			font-size: 50px;
		}

		@media #{$xs} {
			font-size: 28px;
		}
	}
}

.hero-course-1-text {
	h2 {
		font-size: 60px;
		font-weight: 700;
		color: $white;
		margin-bottom: 15px;

		@media #{$md} {
			font-size: 54px;
		}

		@media #{$xs,$sm} {
			font-size: 35px;
		}
	}
}