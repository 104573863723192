.new-footer {
  background: #382d8b;
  border: 1px solid #1e1e1e;
}

@media only screen and (min-width: 1400px) {
  .new-header {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    background: #382d8b;
    border: 1px solid #1e1e1e;
  }
}

.footer-top {
  padding-top: 7rem;
}

.container-sub {
  padding-bottom: 10px;
  padding-top: 0px;
  padding-left: 70px;
  padding-right: 70px;
}

.footer-space-right {
  margin-right: 1rem;
}
.footer-space-right:hover {
  color: #f8a401 !important;
}
.footer-sub-top {
  padding-top: 4rem;
}

.bottom-items {
  display: flex;
}

.flex-sub-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  padding: 20px 0px;
  font-weight: 300;
  /* identical to box height */

  color: #c9c9c9;
}

.footer-icons-top {
  padding-top: 1rem;
}
.footer-iconx {
  padding-right: 2rem;
  color: #f8a401 !important;
}
.footer-icon-col {
  color: #f8a401 !important;
  display: flex;
  font-size: 25px;
  align-items: center;
}

.footer-heading {
  font-weight: 500;
  padding-bottom: 1rem;
  color: #fff;
}

.footer-heading-white {
  font-weight: 500;
  padding-bottom: 1rem;
  color: #fff;
}

.footer-heading:hover {
  color: #f8a401 !important;
  cursor: pointer;
}

.footer-mute {
  color: #d9d9d9;
  font-weight: 400;
  font-size: 15px;
}

.footer-mute-white {
  color: #d9d9d9;
  font-weight: 400;
  font-size: 15px;
}
.footer-mute:hover {
  color: #f8a401 !important;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .footer-sub-top {
    margin-top: -3rem;
  }
  .footer-mute {
    margin-top: 0.1rem;
    font-size: 16px;
  }
  .container-sub {
    padding-bottom: 10px;
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-space-right {
    margin-top: 1rem;
  }

  .footer-heading {
    font-weight: 500;
    padding-top: 2rem;
    color: #fff;
  }

  .bottom-items {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: left;
  }
  .copy-right-ed {
    margin-top: 4rem;
  }
  .flex-sub-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    line-height: 14px;
    padding: 40px 0px;
    font-weight: 300;
    /* identical to box height */

    color: #c9c9c9;
  }

  .footer-icon-col {
    color: #f8a401 !important;
    display: flex;
    font-size: 25px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    align-items: center;
  }
}
