/*----------

04. section-title style

----------*/

@use "../abstract" as *;

// down-mark-line-style

.down-mark-line {
	position: relative;
	z-index: 2;
	display: inline-block;
	&::before {
		position: absolute;
		content: "";
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: -1;
		height: 100%;
		background: url(../../img/icon/down-mark-line.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
		-webkit-animation: section-animation 3s infinite;
		animation: section-animation 3s infinite;
	}
}
@keyframes section-animation {
	0% {
		width: 0;
	}
	15% {
		width: 100%;
	}
	85% {
		opacity: 1;
	}
	90% {
		width: 100%;
		opacity: 0;
	}
	to {
		width: 0;
		opacity: 0;
	}
}

.down-mark-line-2 {
	position: relative;
	z-index: 2;
	display: inline-block;
	&::before {
		position: absolute;
		content: "";
		left: 0;
		bottom: 8%;
		width: 100%;
		z-index: -1;
		height: 100%;
		background: url(../../img/icon/down-mark-line-2.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
		-webkit-animation: section-animation 3s infinite;
		animation: section-animation 3s infinite;
	}
}
