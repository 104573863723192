.dashboard {
  overflow-x: hidden;
  background-color: #fff !important;
  height: 100vh;
}

.edy-menu:hover {
  color: #382d8b;
}

.edy-menu {
  font-size: 15px !important;
}

.sidebar-active-p {
  height: 45px !important;
  background: #faf9ff;
  border-left: 7px solid #382d8b;
  border-radius: 0px 16px 16px 0px;
  padding-left: 10px;
}
.menu-p {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-left: 0.7rem;
  color: #27292c;
}

.menu-p-active {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-left: 0.7rem;
  color: #382d8b;
}

.justify-content-menu {
  margin-left: 2.5rem;
  font-size: 16px;
}

.logout {
  position: absolute !important;
  bottom: -355px !important;
}

.navbar-new {
  padding: 0rem !important;
  position: fixed !important;
  top: 0 !important;
  margin: 25px 20px 25px 0px;
}

.span {
  font-size: 12px !important;
}

.dash-logo {
  height: 26px;
}
.nav-hover {
  color: #b4b4bb;
  line-height: 21px;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  margin-bottom: 0.5rem;
  width: 240px;
}
.brand {
  margin-left: 1.5rem;
}
.nav-hover:hover,
.active {
  background: #f3fff6;
  color: #347345;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
.nav-hover:hover path {
  fill: #347345;
}
.active path {
  fill: #347345;
}
.active .not-fill path {
  fill: #fff !important;
}
.side-width {
  width: 221px;
}
.top-top {
  padding-top: 3.1rem;
}
.top-2 {
  padding-top: 4em;
  padding-left: 40px;
  height: 100vh;
  padding-right: 40px;
  /* height: 850px; */
}
.col-pd {
  padding: 0rem !important;
  background-color: #f8f8f8;
}
.col-pd-2 {
  padding: 0rem !important;
}
.top-main {
  position: fixed !important;
  width: 100% !important;
  top: 0 !important;
  right: 0;
  left: 0;
  z-index: 2;
}

.sub-rite {
  padding-right: 2rem;
}
.sub-ritey {
  padding-right: 0.6rem;
}

.top-main-sub {
  padding: 35px;
  background-color: #fff;
  padding-left: 35px;
  height: 80px;
}

.top-main .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.notif {
  margin-top: -20px;
}
.top-line {
  height: 85px;
  border-right: 1px solid #e1e1e3;
}
.profile-name {
  font-size: 14px;
  line-height: 21px;
}
.profile-drop-text {
  font-size: 14px;
}
.no-deco {
  text-decoration: none !important;
}
.notif-drop {
  width: 377px !important;
  max-height: 300px !important;
  margin-top: 40px !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  overflow-y: auto;
}
.nothing-here {
  margin-top: 50px;
}
.chat-height {
  font-size: 12px;
}

.user-profile-pic {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.show-on-mobile {
  display: none;
}
.icon-sm {
  width: 23px !important;
}
.drop-menu {
  position: relative !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0px !important;
}

@media screen and (max-width: 960px) {
  .top-main {
    display: none;
  }
  .nav-hover {
    width: 100%;
  }
  .side-width {
    width: auto;
  }
  .brand {
    margin-left: 0rem !important;
  }
  .top-2 {
    padding-top: 1rem;
    padding-left: 25px;
    padding-right: 25px;
  }

  .profile-mobile {
    font-weight: 600;
    font-size: 13.5833px;
    line-height: 22px;

    /* identical to box height */
    color: #382d8b;
  }

  .user-name {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height */

    color: #333333;
  }
  .navbar-new {
    z-index: 1000;
    background-color: #fff;
    width: 100%;
    margin: 0px;
    padding: 1em !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .show-on-mobile {
    display: block;
  }

  .mobile-top-main {
    height: 80px;
    left: 0px;
    top: 0px;
    position: sticky;
    background: #ffffff;
  }

  .container-mobile-pad {
    padding: 22px;
  }

  .draw-mobile-body {
    padding: 20px;
  }

  .left-active-border {
    border-left: 4px solid #382d8b;
    height: 30px;
    padding-left: .8rem !important;
  }
  .menu-mobile-active {
    font-weight: 600;
    font-size: 14.5833px;
    line-height: 22px;
    color: #382d8b;
  }

  .menu-mobile {
    font-weight: 500;
    font-size: 14.5833px;
    line-height: 22px;
    /* identical to box height */
    color: #6b717f;
  }
  .logout-mobile {
    position: absolute;
    bottom: 80px;
  }
}
