// Solid Colors ---------------
$white: #ffffff;
$black: #000;

// Grey Colors ---------------
$theme-color-2: #FFB013;

// Grey-2Colors ---------------
$theme-color-2-2: #A2A2A2;


// Footer Colors ---------------
$footer-bg: #000;
$dark-text-color: #222;



// basic color
$theme-color: #382d8b;



// basic color
$theme-bg: #F6F8FB;



// Text Colors ---------------
$body-text: #575757;



// Border Colors ---------------
$border: #edeef2;

// Heading Colors ---------------
$heading-color: #fff;


// section-bg Colors ---------------
$section-bg: #F6F8FB;