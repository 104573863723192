.col-review-box {
  left: 166px;
  top: 150px;
  width: 100%;
}

.clickable-hov {
  color: #382d8b;
  cursor: pointer;
}

.clickable-hov:hover {
  color: #f8a401;
  cursor: pointer;
}

.flex-file-uploads {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-draft-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-margin {
  margin-left: 3rem;
}

.fill-form-box {
  height: 100% !important;
  width: 100% !important;
  border-radius: 0.960832px !important;
  outline: rgb(183, 183, 183) solid 1px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.container-review {
  margin: 0 auto !important;
  width: 55%;
}

@media only screen and (min-width: 1400px) {
  .container-review {
    margin: 0 auto !important;
    width: 30%;
  }
}

.draft-heading {
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 51px;
  /* identical to box height */

  color: #333333;
}

.draft-content {
  font-weight: 300 !important;
  font-size: 16px;
  color: #333333;
}

.draft-edit {
  font-weight: 800;
  cursor: pointer;
  font-size: 21px;
  line-height: 51px;
  color: #1e00ff;
}

.draft-box {
  left: 166px;
  top: 150px;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 19px;
}

.draft-head-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.draft-head {
  margin-top: 2rem;
}

.drfat-content {
  margin-top: 2rem;
}

.draft-content-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col-rev-summary-box {
  left: 166px;
  top: 150px;
  width: 83%;
  margin-bottom: 6rem;
  border: 1px solid #d6d6c2;
  border-radius: 19px;
}

.order-summary-status {
  color: #fff;
  width: 100%;
  padding: 5px 20px;
  margin-left: 2rem;
  white-space: nowrap;
  background: #b7b7b7;
  border-radius: 99px;
}

.rev-right-space {
  margin-right: 0.8rem;
}

.rev-footer-lead {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-right: 0.2rem;
  color: #27292c;
}

.rev-footer {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #27292c;
}

.flex-summary-bottom {
  display: flex;
  align-items: bottom;
  justify-content: space-between;
  width: 60%;
}

.order-q-col {
  margin-bottom: 1rem;
}

.q-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 51px;
  /* identical to box height */
  color: #382d8b;
}

.order-sum-question {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 51px;
  /* identical to box height */
  color: #333333;
}

.flex-asnwers {
  display: flex;
  align-items: center;
}

.order-sum-ans {
  font-weight: 400;
  font-size: 15px;
  margin-top: -1rem;
  line-height: 51px;
  color: #b7b7b7;
}

.order-summary {
  padding: 40px;
}

.check-error {
  color: red;
  font-size: 13px;
}

.ul-dot {
  list-style-type: square !important;
  list-style-position: outside !important;
  list-style-image: none !important;
}

.top-pad {
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
}

.arrow-req {
  width: 220px;
}

.circle-inner-req {
  font-weight: 700;
  font-size: 26px;
  line-height: 101.5%;
  text-align: center;
  color: #fff;
}

.circle-inner-inactive {
  font-weight: 400;
  font-size: 26px;
  line-height: 101.5%;
  text-align: center;
  color: #b7b7b7;
}

.bottom-title-summary {
  font-weight: 600;
  font-size: 25px;
  line-height: 60px;
  /* identical to box height */

  color: #333333;
}

.bottom-value {
  font-weight: 300;
  font-size: 26px;
  line-height: 60px;
  /* identical to box height */

  color: #9aa2ac;
}

.summary-flex {
  display: flex;
  margin-bottom: 0.7rem !important;
  justify-content: space-between;
  align-items: center;
}

.summary-bottom-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b7b7b7;
}

.top-title-summary {
  font-weight: 600;
  font-size: 18px;
  line-height: 39px;
  /* identical to box height */
  color: #333333;
  margin-right: 3rem;
}

.review-summary-lead {
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 60px;
  /* identical to box height */
  color: #333333;
}

.flex-mob {
  margin-bottom: 0;
}

.review-summary-box {
  margin: auto;
  justify-content: center;
  width: 100%;
  border: 1px solid #d6d6c2;
  border-radius: 23px;
  padding: 20px;
}

.review-summary-box-pay {
  margin: auto;
  justify-content: center;
  width: 80%;
  border: 1px solid #d6d6c2;
  border-radius: 23px;
  padding: 20px;
}

.circle-arrow-req {
  border-radius: 50%;
  background: #382d8b;
  box-shadow: 0px 5.77231px 156.028px 30.4444px rgba(0, 0, 0, 0.06);
  width: 65px;
  height: 65px;
  color: white;
}

.circle-arrow-inactive {
  border-radius: 50%;
  border: 1px solid #382d8b;
  box-shadow: 0px 5.77231px 156.028px 30.4444px rgba(0, 0, 0, 0.06);
  width: 65px;
  height: 65px;
}

.line-req {
  margin-top: 20px;
  width: 220px;
  background: #382d8b;
  height: 1px;
  float: left;
}

.point-req {
  width: 0;
  height: 0;
  margin-top: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #382d8b;
  float: right;
}

.review-lead {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 60px;
  /* identical to box height */
  color: #333333;
}

.pay-type-b {
  width: 60%;
}

.img-input-border {
  border: 2px solid #c4c4c4;
  background: #ffffff;
  height: 150px;
}

@media only screen and (max-width: 920px) {
  .circle-inner-req {
    font-weight: 700;
    font-size: 26px;
    line-height: 101.5%;
    text-align: center;
    color: #fff;
  }

  .top-label-weight {
    margin-top: 0;
  }

  .file-margin {
    margin-left: 0rem !important;
  }

  .container-review {
    margin: 0 auto !important;
    width: 100% !important;
  }

  .flex-mob {
    margin-bottom: 1rem;
  }

  .flex-file-uploads {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .pay-type-b {
    width: 100%;
  }

  .flex-draft-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .flex-asnwers {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .rev-right-space {
    margin-top: 1rem;
  }

  .order-sum-ans {
    font-weight: 400;
    font-size: 15px;
    margin-top: 2rem;
    line-height: 21px;
    color: #b7b7b7;
  }

  .review-summary-box-pay {
    margin: auto;
    justify-content: center;
    width: 100%;
    border: 1px solid #d6d6c2;
    border-radius: 23px;
    padding: 20px;
  }

  .col-rev-summary-box {
    left: 166px;
    top: 150px;
    width: 100%;
    border: 1px solid #d6d6c2;
    border-radius: 19px;
  }

  .order-sum-question {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    color: #333333;
  }

  .circle-inner-inactive {
    font-weight: 400;
    font-size: 26px;
    line-height: 101.5%;
    text-align: center;
    color: #b7b7b7;
  }

  .review-summary-box {
    margin: auto;
    justify-content: center;
    width: 100% !important;
    border: 1px solid #d6d6c2;
    border-radius: 23px;
    padding: 20px;
  }

  .rev-footer-lead {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #27292c;
  }

  .footer-el {
    float: left;
    margin-bottom: 1rem;
  }

  .rev-footer {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #27292c;
  }

  .circle-arrow-req {
    border-radius: 50%;
    background: #382d8b;
    box-shadow: 0px 5.77231px 156.028px 30.4444px rgba(0, 0, 0, 0.06);
    width: 40px;
    height: 40px;
    color: white;
  }

  .circle-arrow-inactive {
    border-radius: 50%;
    border: 1px solid #382d8b;
    box-shadow: 0px 5.77231px 156.028px 30.4444px rgba(0, 0, 0, 0.06);
    width: 40px;
    height: 40px;
  }

  .arrow-req {
    width: 80px;
  }

  .container-review {
    padding: 10px 0px;
  }

  .line-req {
    margin-top: 10px;
    width: 80px;
    background: #382d8b;
    height: 1px;
    float: left;
  }

  .flex-summary-bottom {
    display: block;
    margin-top: 4rem;
  }

  .order-summary-status {
    color: #fff;
    width: 100%;
    padding: 0px 20px;
    white-space: nowrap;
    height: 20px;
    background: #b7b7b7;
    border-radius: 99px;
  }

  .col-review-box {
    left: 166px;
    top: 150px;
    width: 100%;
  }
}