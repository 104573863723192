.new-header {
  left: 0px;
  top: 0px;
  height: 100vh;
  background: #fff;
  color: white !important;
  max-width: 1400px;
  border: 1px solid #fff;
  width: 100%;
  align-items: center;
  margin: 0 auto;
}

.linkss {
  color: #382d8b;
}

.leat-top-text {
  font-weight: 800 !important;
  line-height: 35px;
  text-align: left !important;
  font-size: 16px;
  color: #333333;
  text-align: left;
}

.img-book {
  width: 100%;
}

.show-mobile {
  display: none !important;
}


@media only screen and (min-width: 1400px) {
  .new-header {
    left: 0px;
    height: 100vh;
    border: none !important;
    top: 0px;
    background: #fff;
    color: white !important;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }

  .show-mobile {
    display: none !important;
  }

  .img-book {
    width: 106% !important;
  }

  .header-viewport {
    display: flex;
    margin-top: -50px;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .header-lead {
    font-weight: 600 !important;
    font-size: 48px !important;
    color: #333333 !important;
    font-weight: bolder !important;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.link-hover-ed:hover {
  color: #ffb013 !important;
  cursor: pointer;
}

.react-rater-star.is-active {
  color: #ffb013 !important;
  font-size: 20px;
}

.text-dark {
  color: #333333 !important;
}

.top-border-line {
  border: 1px solid #838692;
}

.download-sample {
  border: 1px solid #333333 !important;
  padding: 6px 22px;
  line-height: 33px;
  border-radius: 3.2564 !important;
  text-align: center;
  color: #333333 !important;
  background: #fff;
}

.sign-up-head {
  border: 1px solid #382d8b !important;
  padding: 3px 17px;
  line-height: 33px;
  border-radius: 3.2564 !important;
  text-align: center;
  color: #fff !important;

  background: #382d8b;
  border-radius: 1.53501px;
}

.sign-up-head:hover {
  border: 1px solid #ffb013 !important;
  padding: 3px 17px;
  line-height: 33px;
  border-radius: 3.2564 !important;
  text-align: center;
  color: #fff !important;

  background: #ffb013;
  border-radius: 1.53501px;
}

.header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
}

.mD-auto {
  margin-left: 1.2rem;
}

.draw-head {
  padding-top: 40px !important;
  padding-bottom: 20px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.space-right {
  padding-right: 2rem;
}

.space-right-margin {
  margin-right: 2rem;
}

.sign-up-border {
  border: 1px solid white !important;
  padding: 6px 22px;
  text-align: center;
  line-height: 33px;
}

.sign-up-border-sm {
  border: 1px solid white !important;
  text-align: center;
  border-radius: 3px;
  padding: 4px 15px;
}

.sign-up-orange {
  border: 1px solid #ffb013 !important;
  padding: 6px 52px;
  line-height: 33px;
  border-radius: 3.2564 !important;
  cursor: pointer;
  text-align: center;
  color: #50469c !important;
  background: #ffb013;
}

.btn-lead-head {
  display: flex;
}

.btn-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sub-lead {
  font-size: 18px;
  color: #333333;
  text-align: left;
}

.sign-up-orange-mob {
  border: 1px solid #ffb013 !important;
  padding: 6px 22px;
  line-height: 33px;
  border-radius: 3.2564 !important;
  width: 40% !important;
  text-align: center;
  color: #50469c !important;
  background: #ffb013;
}

.radius-head {
  position: absolute;
  top: -380px;
  right: -20px;
}

.img-head-top {
  padding-top: 8rem !important;
}

.header-lead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800 !important;
  font-size: 42px;
  padding-top: 8rem !important;
  color: #333333 !important;
}

.btn-lead {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.image-head-lead {
  margin-top: 3rem;
}

.show-web {
  display: block !important;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: white !important;
  border: 1px solid white !important;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media screen and (max-width: 960px) {
  .new-header {
    height: 668px;
    left: 0px;
    top: 0px;
    color: #333333 !important;
  }

  .img-book {
    width: 100% !important;
  }

  .header-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: -1rem;
    align-items: center;
  }

  .show-mobile {
    display: block !important;
  }

  .sign-up-border {
    border: 1px solid white !important;
    padding: 6px 22px;
    margin-top: 2rem;
    text-align: center;
    line-height: 43px;
  }

  .sign-up-orange {
    border: 1px solid #ffb013 !important;
    padding: 17px 22px;
    line-height: 43px;
    border-radius: 3.2564 !important;
    text-align: center;
    color: #50469c !important;
    background: #ffb013;
  }

  .header-viewport {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .show-web {
    display: none !important;
  }

  .btn-lead-head {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  .btn-head {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-lead {
    font-weight: 700;
    font-size: 25px;
    text-align: left;
    line-height: 35px;
    margin-top: -6rem;
    width: 100%;
    color: #3a3a3c;
  }

  .sub-lead {
    font-size: 20px;
    color: #333333;
    margin-top: 1rem;
    text-align: left;
  }

  .space-right-margin {
    margin-right: 0 !important;
  }

  .btn-lead {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
  }

  .sign-up-border-sm {
    border: 1px solid white !important;
    text-align: center;
    border-radius: 3px;
    padding: 4px 10px;
  }

  .sign-up-orange {
    border: 1px solid #ffb013 !important;
    padding: 6px 15px;
    margin-bottom: 1rem;
    line-height: 33px;
    border-radius: 3.2564 !important;
    text-align: center;
    color: #50469c !important;
    background: #ffb013;
  }
}